import { ScheduleAPI } from '@/modules/workflow-designer/api';
import { ScheduleType } from '@/modules/workflow-designer/types';
import * as parser from 'cron-parser';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export function useSchedule() {
    const endDateError = {
        title: 'Invalid Retrieve Until Date',
        description: 'Retrieve Until Date is in the past. Please update it accordingly to continue.',
    };

    const schedulesError = {
        title: 'Invalid Schedules',
        description:
            'One or more schedules are in the past or have no valid executions between start and end date. Please update them accordingly.',
    };

    const streamingSchedulesError = {
        title: 'Invalid Schedules',
        description: 'End Dates of Schedules are in the past. Please update them accordingly to continue.',
    };

    const validateDates = (startDate: string | Date, endDate: string | Date) => {
        if (new Date(endDate) > new Date(startDate)) {
            return '';
        } else {
            return 'End date must be after start date';
        }
    };

    const validateDateParameters = (
        startDate: string | Date,
        endDate: string | Date,
        dayOfWeek: string | number,
        month: string | number,
        dayOfMonth: string | number,
        hour: string | number,
        minute: string | number,
        retrieveOnce: boolean,
    ) => {
        const now = dayjs(new Date()).toISOString();

        let currentDate = null;

        if (startDate > now) {
            // subtract 1 millisecond to include 00:00 time
            const date = new Date(startDate);
            date.setMilliseconds(date.getMilliseconds() - 1);
            currentDate = date.toISOString();
        } else {
            currentDate = now;
        }

        const options = { currentDate, endDate, iterator: true, utc: true };

        try {
            const interval = parser.parseExpression(`${minute} ${hour} ${dayOfMonth} ${month} ${dayOfWeek}`, options);

            // eslint-disable-next-line no-constant-condition
            while (true) {
                try {
                    interval.next();
                    break;
                } catch (e) {
                    return `Invalid schedule: ${
                        retrieveOnce
                            ? 'The hour or minute chosen has already passed.'
                            : 'No valid executions between start and end date'
                    }`;
                }
            }
        } catch (err) {
            return 'Conflict in date parameters';
        }
        return '';
    };

    const validate = (scheduleConfig: any, retrieveOnce = false) => {
        const startDate = dayjs.utc(scheduleConfig.startDate).startOf('day').toISOString();
        const date = retrieveOnce ? scheduleConfig.startDate : scheduleConfig.endDate;
        const endDate = dayjs.utc(date).endOf('day').toISOString();

        const dayOfWeek = scheduleConfig.dayOfWeek ?? '*';
        const month = scheduleConfig.month ?? '*';
        const dayOfMonth = scheduleConfig.dayOfMonth ?? '*';
        const hour = scheduleConfig.hour ?? '*';
        const minute = scheduleConfig.minute ?? '*';

        const dates = validateDates(startDate, endDate);
        const dateParameters = validateDateParameters(
            startDate,
            endDate,
            dayOfWeek,
            month,
            dayOfMonth,
            hour,
            minute,
            retrieveOnce,
        );

        return { dates, dateParameters };
    };

    const deleteSchedules = async (schedules: string[]) => {
        if (schedules.length) {
            const promises: any = [];
            schedules.forEach((scheduleId: string) => {
                promises.push(ScheduleAPI.delete(scheduleId));
            });
            await Promise.all(promises);
        }
    };

    const validEndDate = (endDate: string | Date) => {
        const inclusiveDate = dayjs.utc(endDate).add(1, 'day'); // add 1 extra day in order to make it inclusive
        const today = dayjs().utc();
        return !inclusiveDate.isBefore(today);
    };

    const validStreamingSchedules = (schedules: ScheduleType[]) => {
        const today = dayjs().utc();
        const invalidSchedules = schedules.filter((schedule: ScheduleType) =>
            dayjs.utc(schedule.endDate).add(1, 'day').isBefore(today),
        );
        return invalidSchedules.length === 0;
    };

    const isValidSchedule = (scheduleConfig: ScheduleType, retrieveOnce: boolean) => {
        const { dates, dateParameters } = validate(scheduleConfig, retrieveOnce);
        return !dates.length && !dateParameters.length;
    };

    const validSchedules = (schedules: ScheduleType[], retrieveOnce: boolean) => {
        const invalidSchedules = schedules.filter((schedule: ScheduleType) => !isValidSchedule(schedule, retrieveOnce));
        return invalidSchedules.length === 0;
    };

    return {
        validate,
        deleteSchedules,
        validEndDate,
        validSchedules,
        validStreamingSchedules,
        endDateError,
        schedulesError,
        streamingSchedulesError,
    };
}
