


































































































































































































































































import { CopyToClipboardButton, SvgImage } from '@/app/components';
import { useFeatureFlags, useFilters } from '@/app/composable';
import store from '@/app/store';
import { HarvesterSourceType, StatusCode } from '@/modules/data-checkin/constants';
import {
    BellIcon,
    ClockIcon,
    DocumentAddIcon,
    DuplicateIcon,
    LinkIcon,
    MinusIcon,
    PencilAltIcon,
    RefreshIcon,
    TrashIcon,
} from '@vue-hero-icons/outline';
import {
    AdjustmentsIcon,
    BanIcon,
    CalendarIcon,
    CheckIcon,
    DotsHorizontalIcon,
    ExclamationIcon,
    LockClosedIcon,
    UserIcon,
    XIcon,
} from '@vue-hero-icons/solid';
import { computed, defineComponent, PropType } from '@vue/composition-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import * as R from 'ramda';
import AccessLevelDynamicBadge from '../../../app/components/AccessLevelDynamicBadge.vue';

dayjs.extend(utc);

export default defineComponent({
    name: 'JobOverview',
    components: {
        UserIcon,
        CalendarIcon,
        TrashIcon,
        PencilAltIcon,
        DocumentAddIcon,
        DuplicateIcon,
        LinkIcon,
        ClockIcon,
        RefreshIcon,
        AdjustmentsIcon,
        CheckIcon,
        DotsHorizontalIcon,
        XIcon,
        ExclamationIcon,
        BanIcon,
        LockClosedIcon,
        SvgImage,
        AccessLevelDynamicBadge,
        CopyToClipboardButton,
        BellIcon,
        MinusIcon,
    },
    props: {
        job: {
            type: Object,
            required: true,
        },
        queryParams: {
            type: String,
            default: '{}',
        },
        alert: {
            type: Object as PropType<{ entityId: string | number; alertId: string; enabled: boolean }>,
            required: false,
        },
    },
    setup(props, { emit, root }: { emit: any; root: any }) {
        const { formatDate, formatDateTime } = useFilters();
        const { flag } = useFeatureFlags();
        const isAlertsEnabled = flag('alerts');

        const canRetry = computed(() => props.job.executionStatus === StatusCode.Cancelled);

        const orderedSteps = computed(() => {
            return R.sort(R.ascend<any>(R.prop('order')), props.job.dataCheckinJobSteps);
        });

        const statusStep: any = computed(() => {
            for (let idx = 0; idx < orderedSteps.value.length; idx += 1) {
                if (
                    orderedSteps.value[idx].status === StatusCode.Configuration ||
                    orderedSteps.value[idx].status === StatusCode.Update
                ) {
                    return orderedSteps.value[idx];
                }
            }

            for (let idx = 0; idx < orderedSteps.value.length; idx += 1) {
                if (orderedSteps.value[idx].status !== StatusCode.Completed) {
                    return orderedSteps.value[idx];
                }
            }
            return orderedSteps.value[orderedSteps.value.length - 1];
        });

        const statusMessage = computed(() => {
            if (canRetry.value) return StatusCode.Cancelled;
            if (props.job.executionStatus === StatusCode.Suspended) return StatusCode.Suspended;
            if (props.job.executionStatus === StatusCode.Configuration)
                return `${props.job.executionStatus}:  ${statusStep.value.dataCheckinStepType.name}`;
            return props.job.executionStatus;
        });

        const statusClass = computed(() => {
            switch (props.job.executionStatus) {
                case StatusCode.Failed:
                    return 'text-red-700 bg-red-200';
                case StatusCode.Cancelled:
                case StatusCode.Suspended:
                    return 'text-orange-700 bg-orange-200';
                case StatusCode.Completed:
                    return 'text-green-700 bg-green-200';
                case StatusCode.Queued:
                    return 'text-purple-700 bg-purple-200';
                case StatusCode.Running:
                    return 'text-blue-700 bg-blue-200';
                case StatusCode.Idle:
                    return 'text-teal-700 bg-teal-200';
                case StatusCode.Deprecated:
                    return 'text-red-700 bg-red-100';
                case StatusCode.Configuration:
                default:
                    return 'text-neutral-700 bg-neutral-300';
            }
        });

        const harvesterSource = computed((): { label: string; colour: string; fileType?: string } | null => {
            let harvesterOption = null;
            if (props.job?.harvesterSource) {
                switch (props.job.harvesterSource) {
                    case HarvesterSourceType.Api:
                        harvesterOption = { label: 'Data Provider API', colour: 'bg-orange-600' };
                        break;
                    case HarvesterSourceType.InternalApi:
                        harvesterOption = { label: 'Platform API', colour: 'bg-secondary-600' };
                        break;
                    case HarvesterSourceType.File:
                        harvesterOption = {
                            label: 'File',
                            colour: 'bg-primary-600',
                        };
                        break;
                    case HarvesterSourceType.LargeFiles:
                        harvesterOption = {
                            label: 'Large Files',
                            colour: 'bg-indigo-600',
                        };
                        break;
                    case HarvesterSourceType.Kafka:
                        harvesterOption = {
                            label: 'Kafka',
                            colour: 'bg-teal-600',
                        };
                        break;
                    case HarvesterSourceType.ExternalKafka:
                        harvesterOption = {
                            label: 'External Kafka',
                            colour: 'bg-purple-600',
                        };
                        break;
                    case HarvesterSourceType.MQTT:
                        harvesterOption = {
                            label: 'MQTT',
                            colour: 'bg-pink-800',
                        };
                        break;
                    case HarvesterSourceType.ExternalMQTT:
                        harvesterOption = {
                            label: 'External MQTT',
                            colour: 'bg-success-600',
                        };
                        break;
                    default:
                        return { label: 'No harvester option', colour: 'bg-red-600' };
                }

                if (props.job.harvesterSource !== HarvesterSourceType.InternalApi && props.job.fileType) {
                    harvesterOption['fileType'] = ['parquet', 'other'].includes(props.job.fileType)
                        ? props.job.fileType
                        : props.job.fileType.toUpperCase();
                }
            }
            return harvesterOption;
        });

        const user = computed(() => store.state.auth.user);
        const isUserJobCreator = computed(() => user.value.id === Number(props.job?.createdBy?.id));

        const editJob = async (job: any) => {
            if (props.job.executionStatus === StatusCode.Suspended) {
                (root as any).$toastr.w(
                    `This data check-in pipeline is suspended as its output asset (dataset) was deleted. You need to edit the Loader step and configure a new output asset in order to be able to use this pipeline again.`,
                    'Warning',
                );
            } else {
                emit('edit', job);
            }
        };

        const appendData = async (job: any) => {
            root.$router.push({ name: 'harvester', params: { id: job.id, queryParams: props.queryParams } });
        };

        const toSteps = async (name: string, jobId: number) => {
            if (name === 'harvester' && props.job.executionStatus === StatusCode.Suspended) {
                (root as any).$toastr.w(
                    `This data check-in pipeline is suspended as its output asset (dataset) was deleted. You need to edit the Loader step and configure a new output asset in order to be able to use this pipeline again`,
                    'Warning',
                );
            }
            root.$router.push({ name, params: { id: jobId, queryParams: props.queryParams } });
        };

        const destroyJob = async (job: any) => {
            if (!job.canUse) {
                (root as any).$toastr.e('The Data Check-in Pipeline is locked', 'Error');
            } else {
                emit('destroy', job);
            }
        };

        const isFinalized = computed(() => {
            if (props.job.executionStatus === StatusCode.Suspended) return true;
            return props.job.dataCheckinJobSteps.every(
                (step: any) => !!step && step.status !== StatusCode.Configuration && step.status !== StatusCode.Update,
            );
        });

        const accessLevel = computed(() => props.job && props.job?.accessLevel);

        return {
            HarvesterSourceType,
            dayjs,
            formatDate,
            formatDateTime,
            statusMessage,
            statusClass,
            StatusCode,
            orderedSteps,
            statusStep,
            canRetry,
            isUserJobCreator,
            editJob,
            appendData,
            toSteps,
            destroyJob,
            isFinalized,
            accessLevel,
            harvesterSource,
            isAlertsEnabled,
        };
    },
});
