






















































import { ExclamationIcon, InformationCircleIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent } from '@vue/composition-api';
import HtmlModal from './HtmlModal.vue';

export default defineComponent({
    name: 'ConfirmModal',
    props: {
        color: {
            type: String,
            default: 'red',
        },
        title: {
            type: String,
            default: 'Delete record',
        },
        message: {
            type: String,
            default:
                'Are you sure you want to delete this record? All the related data will be permanently removed. This action cannot be undone',
        },
        okButtonText: {
            type: String,
            default: 'Delete',
        },
        cancelButtonText: {
            type: String,
            default: 'Cancel',
        },
        icon: {
            type: String,
            default: 'exclamation', // exclamation, info
        },
        loading: {
            type: Boolean,
            default: false,
        },
        showCancelButton: {
            type: Boolean,
            default: true,
        },
    },
    components: { HtmlModal, ExclamationIcon, InformationCircleIcon },
    setup(props) {
        const iconColors = {
            blue: 'bg-blue-100 text-blue-600',
            green: 'bg-green-100 text-green-600',
            primary: 'bg-primary-100 text-primary-600',
            red: 'bg-red-100 text-red-600',
            secondary: 'bg-secondary-100 text-secondary-600',
            yellow: 'bg-yellow-100 text-yellow-600',
        };
        const buttonColors = {
            blue: 'bg-blue-600 hover:bg-blue-500 focus:border-blue-700 focus:ring-blue',
            green: 'bg-green-600 hover:bg-green-500 focus:border-green-700 focus:ring-green',
            primary: 'bg-primary-600 hover:bg-primary-500 focus:border-primary-700 focus:ring-primary',
            red: 'bg-red-600 hover:bg-red-500 focus:border-red-700 focus:ring-red',
            secondary: 'bg-secondary-600 hover:bg-secondary-500 focus:border-secondary-700 focus:ring-secondary',
            yellow: 'bg-yellow-600 hover:bg-yellow-500 focus:border-yellow-700 focus:ring-yellow',
        };

        const iconClasses = computed(() => iconColors[props.color]);
        const buttonClasses = computed(() => buttonColors[props.color]);

        return { iconClasses, buttonClasses };
    },
});
