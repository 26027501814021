var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.asset.assetTypeId === 1)?_c('FormBlock',{attrs:{"title":_vm.title,"description":_vm.description,"flat":""}},[(!_vm.loadingFields)?_c('div',{staticClass:"flex flex-wrap items-center text-sm text-neutral-600"},[_c('div',{staticClass:"flex items-center mr-4 space-x-2"},[_c('div',{staticClass:"bg-blue-500 w-2.5 h-2.5 rounded-full"}),_c('div',[_vm._v("Transformation Rules")])]),_c('div',{staticClass:"flex items-center mr-4 space-x-2"},[_c('div',{staticClass:"bg-green-500 w-2.5 h-2.5 rounded-full"}),_c('div',[_vm._v("Cleaning Rules")])]),(_vm.isEnabled('anonymisation'))?_c('div',{staticClass:"flex items-center mr-4 space-x-2"},[_c('div',{staticClass:"bg-yellow-500 w-2.5 h-2.5 rounded-full"}),_c('div',[_vm._v("Anonymisation Rules")])]):_vm._e(),(_vm.isEnabled('encryption'))?_c('div',{staticClass:"flex items-center mr-4 space-x-2"},[_c('div',{staticClass:"bg-red-500 w-2.5 h-2.5 rounded-full"}),_c('div',[_vm._v("Encryption Applied")])]):_vm._e()]):_vm._e(),(_vm.loadingFields)?_c('div',{staticClass:"flex flex-col items-center justify-center h-full text-sm text-gray-500"},[_vm._v(" Loading asset structure. Please wait... ")]):(_vm.dataStructure)?_c('div',{staticClass:"p-2 mt-2 border rounded-lg border-neutral-200 bg-neutral-100"},[_c('DataModelTree',{attrs:{"data":{ children: _vm.dataStructure },"nameField":"key","selectable":_vm.asset.accessLevel !== _vm.AccessLevel.Public,"autoselectChildren":true,"selections":_vm.selectedFields,"disabled":_vm.asset.parquetStatus === 'optimised' || _vm.disabled,"disableSelectedFields":!!_vm.contract.updatingContractId},on:{"change":_vm.selectField},scopedSlots:_vm._u([{key:"node",fn:function(ref){
var data = ref.data;
var nodeName = ref.nodeName;
return [_c('div',{staticClass:"flex flex-col w-full"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('div',{staticClass:"flex space-x-3"},[_c('div',{staticClass:"whitespace-nowrap"},[_vm._v(_vm._s(nodeName))]),(data.type)?_c('div',{staticClass:"inline-flex items-center px-2 ml-2 text-sm text-white capitalize rounded border-primary-600 bg-primary-600"},[_vm._v(" "+_vm._s(data.type)+" ")]):_vm._e()])])])]}},{key:"node-suffix",fn:function(ref){
var data = ref.data;
return [(!_vm.loadingRules)?_c('div',{staticClass:"flex items-center justify-between w-full h-full"},[_c('div',{staticClass:"flex items-center space-x-2"},[(_vm.hasRules(data))?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Processing Rules'),expression:"'Processing Rules'"}],staticClass:"flex items-center px-2 py-1.5 space-x-1 border rounded-md",class:_vm.previewRules.includes(_vm.removeAnonSuffix(data.name))
                                        ? 'border-primary-500'
                                        : 'border-neutral-300'},[(_vm.hasRules(data, 'mapping'))?_c('div',{staticClass:"bg-blue-500 w-2.5 h-2.5 rounded-full"}):_vm._e(),(_vm.hasRules(data, 'cleaning'))?_c('div',{staticClass:"bg-green-500 w-2.5 h-2.5 rounded-full"}):_vm._e(),(_vm.isEnabled('anonymisation') && _vm.hasRules(data, 'anonymisation'))?_c('div',{staticClass:"bg-yellow-500 w-2.5 h-2.5 rounded-full"}):_vm._e(),(_vm.isEnabled('encryption') && _vm.hasRules(data, 'encryption'))?_c('div',{staticClass:"bg-red-500 w-2.5 h-2.5 rounded-full"}):_vm._e()]):_vm._e(),(
                                    _vm.extendedFields.length > 0 &&
                                    !_vm.extendedFields.includes(data.name) &&
                                    _vm.selectedFields.includes(data.name)
                                )?_c('div',{staticClass:"inline-flex items-center px-2 py-0.5 text-xs text-white uppercase bg-red-400 rounded-full"},[_vm._v(" New ")]):_vm._e()]),(
                                ['datetime', 'date'].includes(data.type) &&
                                _vm.selectedFields.includes(data.name) &&
                                _vm.asset.parquetStatus !== 'optimised'
                            )?_c('div',{staticClass:"flex items-center justify-end w-full space-x-2"},[(!_vm.showFiltersModal)?_c('div',{staticClass:"text-sm italic text-neutral-600"},[(_vm.filtersText[data.name])?_c('span',[_vm._v(_vm._s(_vm.filtersText[data.name]))]):_c('span',[_vm._v("All values")])]):_vm._e(),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Filters'),expression:"'Filters'"}],staticClass:"text-neutral-500 hover:text-primary-500 disabled:pointer-events-none",attrs:{"type":"button","disabled":_vm.disabled ||
                                    (_vm.initialSelectedFields.includes(data.name) &&
                                        (!_vm.initialFiltersText[data.name] ||
                                            _vm.initialFiltersText[data.name] === 'All values'))},on:{"click":function($event){return _vm.openFiltersModal(data.name, data.type)}}},[_c('FilterIcon',{staticClass:"w-4 h-4"})],1)]):_vm._e()]):_vm._e()]}},{key:"node-description",fn:function(ref){
                                            var data = ref.data;
return [(data.description)?_c('div',{staticClass:"ml-6 font-sans text-sm text-neutral-500"},[_vm._v(" "+_vm._s(data.description)+" ")]):_vm._e(),(!_vm.loadingRules && _vm.previewRules.includes(_vm.removeAnonSuffix(data.name)))?_c('div',{staticClass:"p-3 my-2 ml-5 space-y-2 text-sm bg-white border rounded-md text-neutral-600 border-neutral-300"},_vm._l((_vm.processingRules[_vm.removeAnonSuffix(data.name)]),function(rule,idx){return _c('div',{key:idx,staticClass:"flex items-center space-x-2"},[_c('div',{staticClass:"w-2.5 h-2.5 rounded-full flex-shrink-0",class:{
                                    'bg-blue-500': rule.type === 'mapping',
                                    'bg-green-500': rule.type === 'cleaning',
                                    'bg-yellow-500': rule.type === 'anonymisation',
                                    'bg-red-500': rule.type === 'encryption',
                                }}),_c('div',{staticClass:"flex flex-wrap"},_vm._l((rule.rule),function(rulePart,partidx){return _c('span',{key:("part-" + partidx),staticClass:"mr-1 text-sm text-neutral-700"},[(rule.type !== 'mapping')?[(partidx % 2 === 0)?_c('span',[_vm._v(_vm._s(rulePart))]):_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(rulePart))])]:_c('span',{domProps:{"innerHTML":_vm._s(rulePart)}})],2)}),0)])}),0):_vm._e()]}}],null,false,447041993)}),(_vm.asset.parquetStatus !== 'optimised' && _vm.showFiltersModal)?_c('FiltersModal',{attrs:{"type":_vm.addingFiltersTo.type,"filters":_vm.addingFiltersTo.filters,"initialFilters":_vm.initialFilters[_vm.addingFiltersTo.name],"temporalCoverage":_vm.asset.metadata.extent.temporalCoverage,"isExtended":!!_vm.contract.updatingContractId},on:{"cancel":_vm.closeFiltersModal,"save-filters":_vm.addFilters,"remove-filters":_vm.removeFilters}}):_vm._e()],1):_vm._e()]):(_vm.asset.assetTypeId === 2 && _vm.resultFields.length > 0)?_c('FormBlock',{attrs:{"title":"Result Info","description":"Details about the result structure.","flat":""}},[_c('div',{staticClass:"p-2 mt-2 border rounded-lg border-neutral-200 bg-neutral-100"},[_c('DataModelTree',{attrs:{"data":{ children: _vm.resultFields },"nameField":"key","selectable":"","disabled":"","selectionKey":"key","selections":Object.keys(_vm.asset.structure.schema)},scopedSlots:_vm._u([{key:"node",fn:function(ref){
                                var data = ref.data;
                                var nodeName = ref.nodeName;
return [_c('div',{staticClass:"flex flex-col w-full"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('div',{staticClass:"flex space-x-3"},[_c('div',[_vm._v(_vm._s(nodeName))]),(data.type)?_c('div',{staticClass:"inline-flex items-center px-2 ml-2 text-sm text-white capitalize rounded border-primary-600 bg-primary-600"},[_vm._v(" "+_vm._s(data.type)+" ")]):_vm._e()])])])]}}])})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }