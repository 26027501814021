import { AccessLevelsExtensiveOptions } from '@/modules/access-policy/constants/access-levels.constants';

// distribution
const typeOptions = {
    Text: 'Text',
    Image: 'Image',
    Model: 'Model',
    Video: 'Video',
    Audio: 'Audio',
    'Text and Binary': 'Text and Binary',
};
const textOptions = ['CSV', 'JSON', 'XML'];
const textBinaryOptions = ['JSON and Binary'];
const imageOptions = ['JPG', 'JPX', 'PNG', 'GIF', 'WEBP', 'CR2', 'TIF', 'BMP', 'JXR', 'PSD', 'ICO', 'HEIC'];
const modelOptions = ['IFC', 'IDF', 'BCF', 'RVT'];
const videoOptions = ['MP4', 'M4V', 'MKV', 'WEBM', 'MOV', 'AVI', 'WMV', 'MPG', 'FLV'];
const audioOptions = ['MID', 'MP3', 'M4A', 'OGG', 'FLAC', 'WAV', 'AMR'];
const languageOptions = {
    Abkhazian: 'Abkhazian',
    Afar: 'Afar',
    Afrikaans: 'Afrikaans',
    Akan: 'Akan',
    Albanian: 'Albanian',
    Amharic: 'Amharic',
    Arabic: 'Arabic',
    Aragonese: 'Aragonese',
    Armenian: 'Armenian',
    Assamese: 'Assamese',
    Avaric: 'Avaric',
    Avestan: 'Avestan',
    Aymara: 'Aymara',
    Azerbaijani: 'Azerbaijani',
    Bambara: 'Bambara',
    Bashkir: 'Bashkir',
    Basque: 'Basque',
    Belarusian: 'Belarusian',
    Bengali: 'Bengali',
    'Bihari languages': 'Bihari languages',
    Bislama: 'Bislama',
    Bosnian: 'Bosnian',
    Breton: 'Breton',
    Bulgarian: 'Bulgarian',
    Burmese: 'Burmese',
    'Catalan, Valencian': 'Catalan, Valencian',
    'Central Khmer': 'Central Khmer',
    Chamorro: 'Chamorro',
    Chechen: 'Chechen',
    'Chichewa, Chewa, Nyanja': 'Chichewa, Chewa, Nyanja',
    Chinese: 'Chinese',
    'Church Slavonic, Old Bulgarian, Old Church Slavonic': 'Church Slavonic, Old Bulgarian, Old Church Slavonic',
    Chuvash: 'Chuvash',
    Cornish: 'Cornish',
    Corsican: 'Corsican',
    Cree: 'Cree',
    Croatian: 'Croatian',
    Czech: 'Czech',
    Danish: 'Danish',
    'Divehi, Dhivehi, Maldivian': 'Divehi, Dhivehi, Maldivian',
    'Dutch, Flemish': 'Dutch, Flemish',
    Dzongkha: 'Dzongkha',
    English: 'English',
    Esperanto: 'Esperanto',
    Estonian: 'Estonian',
    Ewe: 'Ewe',
    Faroese: 'Faroese',
    Fijian: 'Fijian',
    Finnish: 'Finnish',
    French: 'French',
    Fulah: 'Fulah',
    'Gaelic, Scottish Gaelic': 'Gaelic, Scottish Gaelic',
    Galician: 'Galician',
    Ganda: 'Ganda',
    Georgian: 'Georgian',
    German: 'German',
    'Gikuyu, Kikuyu': 'Gikuyu, Kikuyu',
    'Greek (Modern)': 'Greek (Modern)',
    'Greenlandic, Kalaallisut': 'Greenlandic, Kalaallisut',
    Guarani: 'Guarani',
    Gujarati: 'Gujarati',
    'Haitian, Haitian Creole': 'Haitian, Haitian Creole',
    Hausa: 'Hausa',
    Hebrew: 'Hebrew',
    Herero: 'Herero',
    Hindi: 'Hindi',
    'Hiri Motu': 'Hiri Motu',
    Hungarian: 'Hungarian',
    Icelandic: 'Icelandic',
    Ido: 'Ido',
    Igbo: 'Igbo',
    Indonesian: 'Indonesian',
    'Interlingua (International Auxiliary Language Association)':
        'Interlingua (International Auxiliary Language Association)',
    Interlingue: 'Interlingue',
    Inuktitut: 'Inuktitut',
    Inupiaq: 'Inupiaq',
    Irish: 'Irish',
    Italian: 'Italian',
    Japanese: 'Japanese',
    Javanese: 'Javanese',
    Kannada: 'Kannada',
    Kanuri: 'Kanuri',
    Kashmiri: 'Kashmiri',
    Kazakh: 'Kazakh',
    Kinyarwanda: 'Kinyarwanda',
    Komi: 'Komi',
    Kongo: 'Kongo',
    Korean: 'Korean',
    'Kwanyama, Kuanyama': 'Kwanyama, Kuanyama',
    Kurdish: 'Kurdish',
    Kyrgyz: 'Kyrgyz',
    Lao: 'Lao',
    Latin: 'Latin',
    Latvian: 'Latvian',
    'Letzeburgesch, Luxembourgish': 'Letzeburgesch, Luxembourgish',
    'Limburgish, Limburgan, Limburger': 'Limburgish, Limburgan, Limburger',
    Lingala: 'Lingala',
    Lithuanian: 'Lithuanian',
    'Luba-Katanga': 'Luba-Katanga',
    Macedonian: 'Macedonian',
    Malagasy: 'Malagasy',
    Malay: 'Malay',
    Malayalam: 'Malayalam',
    Maltese: 'Maltese',
    Manx: 'Manx',
    Maori: 'Maori',
    Marathi: 'Marathi',
    Marshallese: 'Marshallese',
    'Moldovan, Moldavian, Romanian': 'Moldovan, Moldavian, Romanian',
    Mongolian: 'Mongolian',
    Nauru: 'Nauru',
    'Navajo, Navaho': 'Navajo, Navaho',
    'Northern Ndebele': 'Northern Ndebele',
    Ndonga: 'Ndonga',
    Nepali: 'Nepali',
    'Northern Sami': 'Northern Sami',
    Norwegian: 'Norwegian',
    'Norwegian Bokmål': 'Norwegian Bokmål',
    'Norwegian Nynorsk': 'Norwegian Nynorsk',
    'Nuosu, Sichuan Yi': 'Nuosu, Sichuan Yi',
    'Occitan (post 1500)': 'Occitan (post 1500)',
    Ojibwa: 'Ojibwa',
    Oriya: 'Oriya',
    Oromo: 'Oromo',
    'Ossetian, Ossetic': 'Ossetian, Ossetic',
    Pali: 'Pali',
    'Panjabi, Punjabi': 'Panjabi, Punjabi',
    'Pashto, Pushto': 'Pashto, Pushto',
    Persian: 'Persian',
    Polish: 'Polish',
    Portuguese: 'Portuguese',
    Quechua: 'Quechua',
    Romansh: 'Romansh',
    Rundi: 'Rundi',
    Russian: 'Russian',
    Samoan: 'Samoan',
    Sango: 'Sango',
    Sanskrit: 'Sanskrit',
    Sardinian: 'Sardinian',
    Serbian: 'Serbian',
    Shona: 'Shona',
    Sindhi: 'Sindhi',
    'Sinhala, Sinhalese': 'Sinhala, Sinhalese',
    Slovak: 'Slovak',
    Slovenian: 'Slovenian',
    Somali: 'Somali',
    'Sotho, Southern': 'Sotho, Southern',
    'South Ndebele': 'South Ndebele',
    'Spanish, Castilian': 'Spanish, Castilian',
    Sundanese: 'Sundanese',
    Swahili: 'Swahili',
    Swati: 'Swati',
    Swedish: 'Swedish',
    Tagalog: 'Tagalog',
    Tahitian: 'Tahitian',
    Tajik: 'Tajik',
    Tamil: 'Tamil',
    Tatar: 'Tatar',
    Telugu: 'Telugu',
    Thai: 'Thai',
    Tibetan: 'Tibetan',
    Tigrinya: 'Tigrinya',
    'Tonga (Tonga Islands)': 'Tonga (Tonga Islands)',
    Tsonga: 'Tsonga',
    Tswana: 'Tswana',
    Turkish: 'Turkish',
    Turkmen: 'Turkmen',
    Twi: 'Twi',
    'Uighur, Uyghur': 'Uighur, Uyghur',
    Ukrainian: 'Ukrainian',
    Urdu: 'Urdu',
    Uzbek: 'Uzbek',
    Venda: 'Venda',
    Vietnamese: 'Vietnamese',
    Volapük: 'Volapük',
    Walloon: 'Walloon',
    Welsh: 'Welsh',
    'Western Frisian': 'Western Frisian',
    Wolof: 'Wolof',
    Xhosa: 'Xhosa',
    Yiddish: 'Yiddish',
    Yoruba: 'Yoruba',
    'Zhuang, Chuang': 'Zhuang, Chuang',
    Zulu: 'Zulu',
};

// extent
const temporalCoverageOptions = {
    'Not applicable': 'Not applicable',
    'Time Period': 'Time Period',
    'Single Date': 'Single Date',
    Years: 'Years',
    Months: 'Months',
    Days: 'Days',
    Hours: 'Hours',
};
const spatialCoverageOptions = {
    'Not applicable': 'Not applicable',
    'Specific Continent/Countries': 'Specific Continent/Countries',
    'Exact Location': 'Exact Location',
};
const temporalResolutionOptions = {
    'Not applicable': 'Not applicable',
    'Per Second': 'Per Second',
    'Per Minute': 'Per Minute',
    'Per Hour': 'Per Hour',
    'Per Day': 'Per Day',
    'Per Week': 'Per Week',
    'Per Month': 'Per Month',
    'Per Year': 'Per Year',
};

const spatialResolutionOptions = {
    'Not applicable': 'Not applicable',
    'Per Country': 'Per Country',
    'Per Region': 'Per Region',
    'Per City': 'Per City',
    'Per District': 'Per District',
    'Per Building': 'Per Building',
    'Per Zone': 'Per Zone',
    'Per Space': 'Per Space',
    'Per Component': 'Per Component',
    'Per Installation': 'Per Installation',
};

// license
const accessLevelOptions = AccessLevelsExtensiveOptions;

const expectedUsageOptions = {
    centralized: 'In the Centralized Data Spaces',
    //directDownload: 'Through Direct Download in the Data Recipient’s Data Space', // TODO: not available in 1st release
    //onPremise: 'Only in the Data Provider’s On-Premise Data Space', // TODO: not available in 1st release
};

const offlineRetentionOptions = ['Through a Custom API', 'As an (Encrypted) File'];
const otherFilesRetentionOptions = ['As an (Encrypted) File'];

const licenseOptions = [
    {
        id: 'Custom',
        label: 'Custom',
    },
    {
        id: 'Creative Commons',
        label: 'Creative Commons',
        children: [
            {
                id: 'Creative Commons Attribution (CC BY)',
                label: 'Creative Commons Attribution (CC BY)',
            },
            {
                id: 'Creative Commons Attribution Share Alike (CC BY-SA)',
                label: 'Creative Commons Attribution Share Alike (CC BY-SA)',
            },
            {
                id: 'Creative Commons Attribution No Derivatives (CC BY-ND)',
                label: 'Creative Commons Attribution No Derivatives (CC BY-ND)',
            },
            {
                id: 'Creative Commons Attribution Non-Commercial (CC BY-NC)',
                label: 'Creative Commons Attribution Non-Commercial (CC BY-NC)',
            },
            {
                id: 'Creative Commons Attribution Non-Commercial Share Alike (CC BY-NC-SA)',
                label: 'Creative Commons Attribution Non-Commercial Share Alike (CC BY-NC-SA)',
            },
            {
                id: 'Creative Commons Attribution Non-Commercial No Derivatives (CC BY-NC-ND)',
                label: 'Creative Commons Attribution Non-Commercial No Derivatives (CC BY-NC-ND)',
            },
            {
                id: 'Creative Commons Zero (CC0)',
                label: 'Creative Commons Zero (CC0)',
            },
            {
                id: 'Creative Commons Public Domain Mark (CC PDM)',
                label: 'Creative Commons Public Domain Mark (CC PDM)',
            },
        ],
    },
    {
        id: 'Community Data License Agreement (CDLA)',
        label: 'Community Data License Agreement (CDLA)',
        children: [
            {
                id: 'CDLA-Sharing 1.0',
                label: 'CDLA-Sharing 1.0',
            },
            {
                id: 'CDLA-Permissive 1.0',
                label: 'CDLA-Permissive 1.0',
            },
        ],
    },
    {
        id: 'Open Data Commons',
        label: 'Open Data Commons',
        children: [
            {
                id: 'Open Data Commons Attribution License (ODC-BY)',
                label: 'Open Data Commons Attribution License (ODC-BY)',
            },
            {
                id: 'Open Data Commons Open Database License (ODC-ODbL)',
                label: 'Open Data Commons Open Database License (ODC-ODbL)',
            },
            {
                id: 'Open Data Commons Public Domain Dedication and License (PDDL)',
                label: 'Open Data Commons Public Domain Dedication and License (PDDL)',
            },
            {
                id: 'Open Data Commons Database Contents License (ODC-DbCL)',
                label: 'Open Data Commons Database Contents License (ODC-DbCL)',
            },
        ],
    },
];
const derivationOptions = { Modify: 'Modify', Excerpt: 'Excerpt', Annotate: 'Annotate', Aggregate: 'Aggregate' };
const requiredOptions = { Required: 'Required', 'Not required': 'Not required' };
const allowedProhibitedOptions = { Allowed: 'Allowed', Prohibited: 'Prohibited' };
const shareAlikeOptions = { Required: 'Required', 'Not required': 'Not required', 'Not applicable': 'Not applicable' };
const targetPurposeOptions = ['Business', 'Academic/Scientific', 'Personal', 'Non-Profit', 'Non-Commercial'];
const licenses = [
    {
        license: 'Creative Commons Attribution (CC BY)',
        accessLevel: 'Public',
        derivation: ['Modify', 'Excerpt', 'Annotate', 'Aggregate'],
        attribution: 'Required',
        reproduction: 'Allowed',
        distribution: 'Allowed',
        targetPurpose: ['Business', 'Academic/Scientific', 'Personal', 'Non-Profit', 'Non-Commercial'],
        offlineRetention: ['Through a Custom API'],
        expectedUsage: ['centralized'],
        reContext: 'Allowed',
        shareAlike: 'Not required',
        link: 'https://creativecommons.org/licenses/by/4.0/legalcode',
    },
    {
        license: 'Creative Commons Attribution Share Alike (CC BY-SA)',
        accessLevel: 'Public',
        derivation: ['Modify', 'Excerpt', 'Annotate', 'Aggregate'],
        attribution: 'Required',
        reproduction: 'Allowed',
        distribution: 'Allowed',
        targetPurpose: ['Business', 'Academic/Scientific', 'Personal', 'Non-Profit', 'Non-Commercial'],
        offlineRetention: ['Through a Custom API'],
        expectedUsage: ['centralized'],
        reContext: 'Allowed',
        shareAlike: 'Required',
        link: 'https://creativecommons.org/licenses/by-sa/4.0/legalcode',
    },
    {
        license: 'Creative Commons Attribution No Derivatives (CC BY-ND)',
        accessLevel: 'Public',
        derivation: ['Prohibited'],
        attribution: 'Required',
        reproduction: 'Allowed',
        distribution: 'Prohibited',
        targetPurpose: ['Business', 'Academic/Scientific', 'Personal', 'Non-Profit', 'Non-Commercial'],
        offlineRetention: ['Through a Custom API'],
        expectedUsage: ['centralized'],
        reContext: 'Allowed',
        shareAlike: 'Not applicable',
        link: 'https://creativecommons.org/licenses/by-nd/4.0/legalcode',
    },
    {
        license: 'Creative Commons Attribution Non-Commercial (CC BY-NC)',
        accessLevel: 'Public',
        derivation: ['Modify', 'Excerpt', 'Annotate', 'Aggregate'],
        attribution: 'Required',
        reproduction: 'Allowed',
        distribution: 'Allowed',
        targetPurpose: ['Non-Commercial'],
        offlineRetention: ['Through a Custom API'],
        expectedUsage: ['centralized'],
        reContext: 'Allowed',
        shareAlike: 'Not required',
        link: 'https://creativecommons.org/licenses/by-nc/4.0/legalcode',
    },
    {
        license: 'Creative Commons Attribution Non-Commercial Share Alike (CC BY-NC-SA)',
        accessLevel: 'Public',
        derivation: ['Modify', 'Excerpt', 'Annotate', 'Aggregate'],
        attribution: 'Required',
        reproduction: 'Allowed',
        distribution: 'Allowed',
        targetPurpose: ['Non-Commercial'],
        offlineRetention: ['Through a Custom API'],
        expectedUsage: ['centralized'],
        reContext: 'Allowed',
        shareAlike: 'Required',
        link: 'https://creativecommons.org/licenses/by-nc-sa/4.0/legalcode',
    },
    {
        license: 'Creative Commons Attribution Non-Commercial No Derivatives (CC BY-NC-ND)',
        accessLevel: 'Public',
        derivation: ['Prohibited'],
        attribution: 'Required',
        reproduction: 'Allowed',
        distribution: 'Allowed',
        targetPurpose: ['Non-Commercial'],
        offlineRetention: ['Through a Custom API'],
        expectedUsage: ['centralized'],
        reContext: 'Allowed',
        shareAlike: 'Not applicable',
        link: 'https://creativecommons.org/licenses/by-nc-nd/4.0/legalcode',
    },
    {
        license: 'Creative Commons Zero (CC0)',
        accessLevel: 'Public',
        derivation: ['Modify', 'Excerpt', 'Annotate', 'Aggregate'],
        attribution: 'Not required',
        reproduction: 'Allowed',
        distribution: 'Allowed',
        targetPurpose: ['Business', 'Academic/Scientific', 'Personal', 'Non-Profit', 'Non-Commercial'],
        offlineRetention: ['Through a Custom API'],
        expectedUsage: ['centralized'],
        reContext: 'Allowed',
        shareAlike: 'Not required',
        link: 'https://creativecommons.org/publicdomain/zero/1.0/legalcode',
    },
    {
        license: 'Creative Commons Public Domain Mark (CC PDM)',
        accessLevel: 'Public',
        derivation: ['Modify', 'Excerpt', 'Annotate', 'Aggregate'],
        attribution: 'Not required',
        reproduction: 'Allowed',
        distribution: 'Allowed',
        targetPurpose: ['Business', 'Academic/Scientific', 'Personal', 'Non-Profit', 'Non-Commercial'],
        offlineRetention: ['Through a Custom API'],
        expectedUsage: ['centralized'],
        reContext: 'Allowed',
        shareAlike: 'Not required',
        link: 'https://creativecommons.org/share-your-work/public-domain/pdm/',
    },
    {
        license: 'CDLA-Sharing 1.0',
        accessLevel: 'Public',
        derivation: ['Modify', 'Excerpt', 'Annotate', 'Aggregate'],
        attribution: 'Required',
        reproduction: 'Allowed',
        distribution: 'Allowed',
        targetPurpose: ['Business', 'Academic/Scientific', 'Personal', 'Non-Profit', 'Non-Commercial'],
        offlineRetention: ['Through a Custom API'],
        expectedUsage: ['centralized'],
        reContext: 'Allowed',
        shareAlike: 'Required',
        link: 'https://cdla.io/sharing-1-0/',
    },
    {
        license: 'CDLA-Permissive 1.0',
        accessLevel: 'Public',
        derivation: ['Modify', 'Excerpt', 'Annotate', 'Aggregate'],
        attribution: 'Required',
        reproduction: 'Allowed',
        distribution: 'Allowed',
        targetPurpose: ['Business', 'Academic/Scientific', 'Personal', 'Non-Profit', 'Non-Commercial'],
        offlineRetention: ['Through a Custom API'],
        expectedUsage: ['centralized'],
        reContext: 'Allowed',
        shareAlike: 'Not required',
        link: 'https://cdla.io/permissive-1-0/',
    },
    {
        license: 'Open Data Commons Attribution License (ODC-BY)',
        accessLevel: 'Public',
        derivation: ['Modify', 'Excerpt', 'Annotate', 'Aggregate'],
        attribution: 'Required',
        reproduction: 'Allowed',
        distribution: 'Allowed',
        targetPurpose: ['Business', 'Academic/Scientific', 'Personal', 'Non-Profit', 'Non-Commercial'],
        offlineRetention: ['Through a Custom API'],
        expectedUsage: ['centralized'],
        reContext: 'Allowed',
        shareAlike: 'Not required',
        link: 'https://opendatacommons.org/licenses/by/1-0/index.html',
    },
    {
        license: 'Open Data Commons Open Database License (ODC-ODbL)',
        accessLevel: 'Public',
        derivation: ['Modify', 'Excerpt', 'Annotate', 'Aggregate'],
        attribution: 'Required',
        reproduction: 'Allowed',
        distribution: 'Allowed',
        targetPurpose: ['Business', 'Academic/Scientific', 'Personal', 'Non-Profit', 'Non-Commercial'],
        offlineRetention: ['Through a Custom API'],
        expectedUsage: ['centralized'],
        reContext: 'Allowed',
        shareAlike: 'Required',
        link: 'https://opendatacommons.org/licenses/odbl/1.0/index.html',
    },
    {
        license: 'Open Data Commons Public Domain Dedication and License (PDDL)',
        accessLevel: 'Public',
        derivation: ['Modify', 'Excerpt', 'Annotate', 'Aggregate'],
        attribution: 'Not required',
        reproduction: 'Allowed',
        distribution: 'Allowed',
        targetPurpose: ['Business', 'Academic/Scientific', 'Personal', 'Non-Profit', 'Non-Commercial'],
        offlineRetention: ['Through a Custom API'],
        expectedUsage: ['centralized'],
        reContext: 'Allowed',
        shareAlike: 'Not required',
        link: 'https://opendatacommons.org/licenses/pddl/1.0/index.html',
    },
    {
        license: 'Open Data Commons Database Contents License (ODC-DbCL)',
        accessLevel: 'Public',
        derivation: ['Modify', 'Excerpt', 'Annotate', 'Aggregate'],
        attribution: 'Required',
        reproduction: 'Allowed',
        distribution: 'Allowed',
        targetPurpose: ['Business', 'Academic/Scientific', 'Personal', 'Non-Profit', 'Non-Commercial'],
        offlineRetention: ['Through a Custom API'],
        expectedUsage: ['centralized'],
        reContext: 'Allowed',
        shareAlike: 'Required',
        link: 'https://opendatacommons.org/licenses/dbcl/1-0/index.html',
    },
];

// pricing
const otherPaymentMethodOptions = ['Online through crypto-currency'];
const calculationSchemeOptions = {
    'Fixed per Data Asset': 'Fixed per Data Asset',
    'Fixed per Row': 'Fixed per Row',
    'Request Dependent': 'Request Dependent',
};

const reimbursementMethodOptions = {
    bank: 'Through Payment via a Credit / Debit Card and/or a Bank Transfer',
    online: 'Through a Crypto-Currency Payment in SYNERGIES',
    bartering: 'Available for Bartering',
};
const otherCalculationSchemeOptions = { 'Request Dependent': 'Request Dependent' };
const currencyOptions = { EUR: 'EUR', USD: 'USD', GBP: 'GBP' };

export {
    accessLevelOptions,
    allowedProhibitedOptions,
    audioOptions,
    calculationSchemeOptions,
    currencyOptions,
    derivationOptions,
    expectedUsageOptions,
    imageOptions,
    languageOptions,
    licenseOptions,
    licenses,
    modelOptions,
    offlineRetentionOptions,
    otherCalculationSchemeOptions,
    otherFilesRetentionOptions,
    otherPaymentMethodOptions,
    reimbursementMethodOptions,
    requiredOptions,
    shareAlikeOptions,
    spatialCoverageOptions,
    spatialResolutionOptions,
    targetPurposeOptions,
    temporalCoverageOptions,
    temporalResolutionOptions,
    textBinaryOptions,
    textOptions,
    typeOptions,
    videoOptions,
};
