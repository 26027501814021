import * as R from 'ramda';
import dayjs from 'dayjs';
import { AnonymisationType, GeneralizationMethod, AnonymisationDescriptions } from '../constants';

export function useAnonymisation(root: any | null = null) {
    const getGeneralizationDescription = (generalisation: string) => {
        switch (generalisation) {
            case GeneralizationMethod.Interval:
                return AnonymisationDescriptions.interval;
            case GeneralizationMethod.NumericalGroup:
                return AnonymisationDescriptions.numericalGroup;
            case GeneralizationMethod.Masking:
                return AnonymisationDescriptions.masking;
            case GeneralizationMethod.BooleanGroup:
                return AnonymisationDescriptions.booleanGroup;
            case GeneralizationMethod.Datetime:
                return AnonymisationDescriptions.datetime;
            case GeneralizationMethod.Date:
                return AnonymisationDescriptions.date;
            case GeneralizationMethod.Time:
                return AnonymisationDescriptions.time;
            default:
                return null;
        }
    };

    // Returns the available generalisation methods for a specific type
    const getGeneralizationMethodOptions = (field: any) => {
        switch (field.type) {
            case 'integer':
            case 'float':
            case 'double':
                return [
                    { value: 'interval', label: 'Interval' },
                    { value: 'numerical-group', label: 'Numerical Group' },
                ];
            case 'string':
                return [{ value: 'masking', label: 'Masking' }];
            case 'boolean':
                return [{ value: 'boolean-group', label: 'Boolean Group' }];
            case 'datetime':
                return [{ value: 'datetime', label: 'Datetime' }];
            case 'date':
                return [{ value: 'date', label: 'Date' }];
            case 'time':
                return [{ value: 'time', label: 'Time' }];
            default:
                return [];
        }
    };

    // Initializes a field based on its anonymisation/general type.
    const initializeAnonymizationType = (anonymisationType: string, type: string) => {
        switch (anonymisationType) {
            case AnonymisationType.Insensitive:
                return { generalisation: null, options: null };
            case AnonymisationType.QuasiIdentifier: {
                const replaceWith: any = null;
                const nullValues = { keep: true, replaceWith };
                switch (type) {
                    case 'string':
                        return {
                            generalisation: GeneralizationMethod.Masking,
                            options: {
                                maskingChar: '*',
                                paddingChar: '#',
                                maskingDirection: 'rtl',
                                paddingDirection: 'ltr',
                                nullValues,
                            },
                        };
                    case 'boolean':
                        return {
                            generalisation: GeneralizationMethod.BooleanGroup,
                            options: {
                                show: true,
                                nullValues,
                            },
                        };
                    case 'datetime':
                        return {
                            generalisation: GeneralizationMethod.Datetime,
                            options: {
                                finalLevel: 5,
                                nullValues,
                            },
                        };
                    case 'date':
                        return {
                            generalisation: GeneralizationMethod.Date,
                            options: {
                                finalLevel: 2,
                                nullValues,
                            },
                        };
                    case 'time':
                        nullValues.replaceWith = { hours: '0', minutes: '0', seconds: '0' };
                        return {
                            generalisation: GeneralizationMethod.Time,
                            options: {
                                finalLevel: 3,
                                nullValues,
                            },
                        };
                    default:
                        return {
                            generalisation: null,
                            options: { nullValues },
                        };
                }
            }
            case AnonymisationType.Identifier:
                return {
                    generalisation: null,
                    options: { anonymisationMethod: 'drop' },
                };
            default:
                return { generalisation: null, options: null };
        }
    };

    // Initializes a field based on its generalisation method.
    const initializeGeneralizationMethod = (field: any, method: string) => {
        let nullValues = { keep: true, replaceWith: null };
        if (field.options.nullValues) {
            nullValues = field.options.nullValues;
        }
        switch (method) {
            case GeneralizationMethod.Interval:
                return {
                    options: {
                        leveling: 'auto',
                        levels: [{ interval: null }],
                        nullValues,
                    },
                };
            case GeneralizationMethod.NumericalGroup:
                return {
                    options: {
                        leveling: 'auto',
                        levels: [[{ from: null, to: null, label: null }]],
                        nullValues,
                    },
                };
            default:
                return { options: null };
        }
    };

    // Checks if a field has automated leveling
    const hasAutoLeveling = (field: any) => {
        if (
            (field.generalisation === GeneralizationMethod.Interval ||
                field.generalisation === GeneralizationMethod.NumericalGroup) &&
            field.options.leveling === 'auto'
        ) {
            return true;
        }
        return false;
    };

    /**
     * Converts the replaceWith value (of the null values) to the appropriate format/type.
     * If type is 'time', it converts a time object (with hours, minutes, seconds values) to a time string (e.g. {hours: 10, minutes: 15, seconds: 20} => 10:15:20)
     * If type is 'date', it converts a datetime string to date string (e.g. 2021-01-01T00:00:00 => 2021-01-01)
     * If type is 'datetime', it converts a datetime string to ISO format.
     * If type is 'integer', 'double' or 'boolean', it converts a string value to the appropriate type. (e.g. '3' => 3, '3.15' => 3.15, 'true' => true)
     * @param reverse If true, this function does the opposite modifications (depending on preview/editing mode).
     * @param selectedField The current selected field.
     */
    const convertReplaceNullValues = (reverse: boolean, field: any) => {
        const newField = R.clone(field);
        if (
            newField &&
            newField.anonymisationType === AnonymisationType.QuasiIdentifier &&
            !newField.options.nullValues.keep
        ) {
            if (newField.type === 'time') {
                if (reverse) {
                    const value = newField.options.nullValues.replaceWith;
                    newField.options.nullValues.replaceWith = {
                        hours: parseInt(value.split(':')[0], 10),
                        minutes: parseInt(value.split(':')[1], 10),
                        seconds: parseInt(value.split(':')[2], 10),
                    };
                } else {
                    const value = newField.options.nullValues.replaceWith;
                    const hours = value.hours < 10 ? `0${value.hours}` : value.hours;
                    const minutes = value.minutes < 10 ? `0${value.minutes}` : value.minutes;
                    const seconds = value.seconds < 10 ? `0${value.seconds}` : value.seconds;
                    newField.options.nullValues.replaceWith = `${hours}:${minutes}:${seconds}`;
                }
            } else if (newField.type === 'date') {
                if (!reverse) {
                    const value = new Date(newField.options.nullValues.replaceWith);
                    newField.options.nullValues.replaceWith = dayjs(value).format('YYYY-MM-DD');
                }
            } else if (newField.type === 'datetime') {
                if (!reverse) {
                    const value = new Date(newField.options.nullValues.replaceWith);
                    newField.options.nullValues.replaceWith = value.toISOString();
                }
            } else if (newField.type === 'integer') {
                if (!reverse) {
                    const value = newField.options.nullValues.replaceWith;
                    newField.options.nullValues.replaceWith = parseInt(value, 10);
                }
            } else if (newField.type === 'double') {
                if (!reverse) {
                    const value = newField.options.nullValues.replaceWith;
                    newField.options.nullValues.replaceWith = parseFloat(value);
                }
            } else if (newField.type === 'boolean') {
                if (!reverse) {
                    const value = newField.options.nullValues.replaceWith === 'true';
                    newField.options.nullValues.replaceWith = value;
                }
            }
        }
        return newField;
    };

    // Follows the original path in a sample row and returns the value of a specific field
    const getSampleValue = (row: any, field: any) => {
        let value = row;
        field.originalPath.forEach((key: string) => {
            const keyParts = key.split('[]');
            value = keyParts.length > 1 ? value[keyParts[0]][0] : value[key];
        });
        return value[field.originalName];
    };

    // Returns the length of the largest string in sample
    const getMaxStringLength = (sample: Array<any>, field: any): number => {
        let maxCharacters = 0;
        if (field.type === 'string') {
            sample.forEach((row: any) => {
                let value = getSampleValue(row, field);
                if (value) {
                    if (!R.is(Array, value)) value = [value];
                    value.forEach((item: string) => {
                        if (item.length > maxCharacters) {
                            maxCharacters = item.length;
                        }
                    });
                }
            });
        }
        return maxCharacters;
    };

    // Returns the number of levels of a field
    const getLevelCount = (maxCharacters: number, maxAutoLevels: number, field: any): number => {
        let levels = 0;
        if (field.generalisation === GeneralizationMethod.Masking) {
            levels = maxCharacters;
        } else if (
            field.generalisation === GeneralizationMethod.Datetime ||
            field.generalisation === GeneralizationMethod.Date ||
            field.generalisation === GeneralizationMethod.Time
        ) {
            levels = field.options.finalLevel;
        } else if (hasAutoLeveling(field)) {
            levels = maxAutoLevels;
        } else if (field.generalisation === GeneralizationMethod.BooleanGroup) {
            levels = 1;
        } else {
            levels = field.options.levels.length;
        }
        return levels;
    };

    const mergeNumericalGroups = (level: any) => {
        const newLevel = [];
        if (level.length === 1) {
            newLevel.push(R.clone(level));
        }
        let i = 0;
        while (i < level.length) {
            if (level[i + 1]) {
                let label = null;
                if (level[i].label && level[i].label.length > 0) {
                    label = `${level[i].label},${level[i + 1].label}`;
                }
                newLevel.push({
                    from: level[i].from,
                    to: level[i + 1].to,
                    label,
                });
            } else {
                newLevel.push(R.clone(level[i]));
            }
            i += 2;
        }
        return newLevel;
    };

    // Returns a level based on the level index.
    const getNextLevel = (levelIndex: number, previousLevel: any, field: any) => {
        let level: any = levelIndex;
        // If leveling options is 'auto', calculate the next level based on the previous level.
        if (hasAutoLeveling(field)) {
            level = previousLevel;
            if (field.generalisation === GeneralizationMethod.Interval && levelIndex > 1) {
                level.interval *= 2;
            } else if (field.generalisation === GeneralizationMethod.NumericalGroup && levelIndex > 1) {
                if (level.length > 1) {
                    level = mergeNumericalGroups(level);
                }
            } else {
                level = R.clone(field.options.levels[0]);
            }
        }
        // If leveling option is 'custom', get the level based on the level index.
        if (
            field.generalisation === GeneralizationMethod.Interval ||
            field.generalisation === GeneralizationMethod.NumericalGroup
        ) {
            if (!hasAutoLeveling(field)) level = field.options.levels[levelIndex - 1];
        }
        return level;
    };

    // Validates if a value is integer and is within certain limits.
    const validateInput = (min: number, max: number, value: any, text: string) => {
        if (value.includes('.') || value.includes('e')) {
            (root as any).$toastr.e(`The ${text} must be an integer value.`, 'Invalid Input');
            return false;
        }
        if (value < min || value > max) {
            (root as any).$toastr.e(`The ${text} must be between ${min} and ${max}.`, 'Invalid Input');
            return false;
        }
        return true;
    };

    const extractIntervalRules = (rules: Array<Array<string>>, level: any, hasStats: boolean, options: any) => {
        if (hasStats && level) {
            rules.push(['Field values were generalized in numerical intervals of size', `${level.interval}.`]);
        } else if (!hasStats) {
            let intervalValues = '';
            for (let i = 0; i < options.levels.length; i += 1) {
                intervalValues += `${options.levels[i].interval}, `;
            }
            intervalValues = intervalValues.slice(0, -2);
            rules.push([
                'Field values are likely to be generalized in numerical intervals of size',
                `${intervalValues}.`,
            ]);
            if (options.leveling === 'auto') {
                rules.push(['Additional generalization levels may be applied.']);
            }
        }
    };

    const extractNumericalGroupRules = (rules: Array<Array<string>>, level: any, hasStats: boolean, options: any) => {
        if (hasStats && level) {
            level.forEach((group: any) => {
                let { label } = group;
                if (!label || label.length === 0) {
                    label = `${group.from}-${group.to}`;
                }
                rules.push([
                    'Field values from',
                    group.from,
                    'to',
                    group.to,
                    'were replaced with the label',
                    `'${label}'.`,
                ]);
            });
        } else if (!hasStats) {
            options.levels[0].forEach((group: any) => {
                let { label } = group;
                if (!label || label.length === 0) {
                    label = `${group.from}-${group.to}`;
                }
                rules.push([
                    'Field values from',
                    group.from,
                    'to',
                    group.to,
                    'are likely to be replaced with the label',
                    `'${label}'.`,
                ]);
            });
            if (options.leveling === 'auto' || options.levels > 1) {
                rules.push(['Additional generalization levels may be applied.']);
            }
        }
    };

    const getDatetimeText = (level: number, type: string) => {
        const datetimeParts = ['seconds', 'minutes', 'hours', 'day', 'month', 'year (decade)'];
        const dateParts = ['day', 'month', 'year (decade)'];
        const timeParts = ['seconds', 'minutes', 'hours'];
        if (type === 'datetime') {
            return datetimeParts[level - 1];
        }
        if (type === 'date') {
            return dateParts[level - 1];
        }
        return timeParts[level - 1];
    };

    const extractDatetimeRules = (
        rules: Array<Array<string>>,
        level: any,
        hasStats: boolean,
        type: string,
        options: any,
    ) => {
        if (hasStats) {
            let datetimeValues = '';
            for (let i = 1; i <= level; i += 1) {
                datetimeValues += `${getDatetimeText(i, type)}, `;
            }
            datetimeValues = datetimeValues.slice(0, -2);
            rules.push(['The following parts have been', 'reset', `from the ${type} values:`, `${datetimeValues}.`]);
        } else {
            rules.push([
                'Field values are likely to be generalized by',
                'reseting',
                'different parts of the datetime, starting from',
                'seconds',
                'until',
                `${getDatetimeText(options.finalLevel, type)}.`,
            ]);
        }
    };

    const extractMaskingRules = (rules: Array<Array<string>>, level: any, hasStats: boolean, options: any) => {
        if (hasStats && level === 1) {
            rules.push([
                'The',
                'last',
                'character of the values was replaced with the masking character',
                `'${options.maskingChar}'.`,
            ]);
        } else if (hasStats && level > 1) {
            rules.push([
                'The',
                `last ${level}`,
                'characters of the values were replaced with the masking character',
                `'${options.maskingChar}'.`,
            ]);
        } else if (!hasStats) {
            rules.push([
                'Field values are likely to be obscured by the masking character',
                `'${options.maskingChar}'.`,
            ]);
        }
    };

    const extractBooleanRules = (rules: Array<Array<string>>, hasStats: boolean, options: any) => {
        let verb = 'are likely to be';
        if (hasStats) {
            verb = 'were';
        }
        if (options.show) {
            rules.push([`Field values ${verb} generalized into the`, 'same group.']);
        } else {
            rules.push([`Field values ${verb}`, 'replaced', 'with the character', `'*'.`]);
        }
    };

    return {
        getGeneralizationDescription,
        getGeneralizationMethodOptions,
        initializeAnonymizationType,
        initializeGeneralizationMethod,
        convertReplaceNullValues,
        getMaxStringLength,
        getLevelCount,
        getNextLevel,
        validateInput,
        getSampleValue,
        extractIntervalRules,
        extractNumericalGroupRules,
        extractDatetimeRules,
        extractMaskingRules,
        extractBooleanRules,
    };
}
