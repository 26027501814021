import { computed, Ref, ref } from '@vue/composition-api';
import Papa from 'papaparse';
import * as R from 'ramda';
import { parseString, processors } from 'xml2js';
import { HarvesterSourceType } from '../constants';

export function useHarvester(
    root: any,
    emit: any,
    fileType: Ref<string | null> = ref<string | null>(null),
    validating: Ref<boolean> = ref<boolean>(false),
) {
    const arraySizeLimit = 25;
    const showValidationBar = ref<boolean>(false);
    const noCSVData = ref<boolean>(false);
    const duplicateHeaders = ref<boolean>(false);
    const invalidFormat = ref<boolean>(false);
    const rangeError = ref<boolean>(false);
    const emptyFile = ref<boolean>(false);
    const valPercentage = ref<number>(0);

    /**
     * Sets sample/ processed sample
     * @param sample sample to be processed or uploaded
     * @param source configuration source
     */
    const changeFinalSample = (sample: any, source: HarvesterSourceType) => {
        switch (source) {
            case HarvesterSourceType.ExternalMQTT:
            case HarvesterSourceType.ExternalKafka:
                emit('process-sample', sample);
                break;
            case HarvesterSourceType.File:
                emit('sample-updated', sample);
                break;
            default:
                emit('sample-uploaded', sample);
                break;
        }
    };

    /**
     * Crops response object/ array to specific size
     * @param response response object/ array to be cropped
     * @param size crop response object/ array to this size (defaulted to 10)
     */
    const limitResponse = (response: any, size = 10) => {
        if (R.type(response) === 'Array') {
            return response.slice(0, size);
        }

        if (R.type(response) === 'Object') {
            return Object.keys(response).reduce((result: any, key: string) => {
                Reflect.set(result, key, limitResponse(response[key], size));
                return result;
            }, {});
        }

        return response;
    };

    /**
     * Crops array of values and array of objects down to a specific limit (determined by arraySizeLimit)
     * @param obj object to be cropped
     */
    const reduceSampleValues = (obj: any) => {
        let cropped = false;
        if (typeof obj === 'object') {
            Object.keys(obj).forEach((k) => {
                if (typeof obj[k] === 'object' && obj[k] !== null) {
                    if (obj[k] instanceof Array && obj[k].length > arraySizeLimit) {
                        let isArray = false; // if at least 1 of array's values is array then it means there are more nested levels
                        for (let i = 0; i < obj[k].length; i++) {
                            if (obj[k][i] instanceof Array && obj[k][i] !== null) {
                                isArray = true;
                            }
                        }

                        if (!isArray) {
                            obj[k].splice(arraySizeLimit);
                            cropped = true;
                        }
                    }
                    reduceSampleValues(obj[k]);
                }
            });
        }
        if (cropped) {
            emit('sample-cropped', cropped);
        }
        return obj;
    };

    /**
     * Checks if json is empty
     * @param json json under validation
     */
    const checkEmptyJSON = (json: any): boolean => {
        if (R.isNil(json) || R.isEmpty(json)) {
            return true;
        }
        if (R.type(json) === 'Array') {
            return checkEmptyJSON(json[0]);
        }
        return false;
    };

    /**
     * Shows an error message to the user and removes uploaded sample because it is invalid or empty
     * @param message the error message displayed to the user
     */
    const wrongJSONFile = (message: string) => {
        (root as any).$toastr.e(message, 'Error');
        emit('sample-uploaded', null);
        emit('files-changed', { sample: null });
        emit('parsed-sample-uploaded', null);
    };

    /**
     * Checks whether a file is of valid JSON format, crops the file data to a specific size if required and checks if non empty
     * @param file file under validation
     */
    const parseJSON = async (file: any) => {
        if (!file) return;
        const data = await file.text();
        let json = null;
        // Check if JSON file is empty
        if (checkEmptyJSON(data)) {
            wrongJSONFile('Empty JSON file!');
            return;
        }
        // Check if JSON file is valid
        try {
            json = JSON.parse(data);
            if (R.type(json) !== 'Array' && R.type(json) !== 'Object') {
                wrongJSONFile('Invalid JSON format!');
                return;
            }
            // If valid then crop the file data to a specific size
            json = reduceSampleValues(json);
        } catch (e) {
            wrongJSONFile('Invalid JSON format!');
            return;
        }
        // Check if JSON file is empty
        if (checkEmptyJSON(json)) {
            wrongJSONFile('Empty JSON file!');
            return;
        }
        emit('sample-uploaded', json);
        emit('parsed-sample-uploaded', json);
    };

    /**
     * Checks whether a file is of valid XML format
     * @param file file under validation
     */
    const checkInvalidXML = async (file: any) => {
        const data = await file.text();
        parseString(data, (err, result) => {
            emptyFile.value = result === null;
            if (err || !result) {
                invalidFormat.value = true;
            } else {
                invalidFormat.value = false;
            }
        });
    };

    /**
     * Checks whether a parquet file has valid format
     * @param file file under validation
     */
    const checkInvalidParquet = async (file: any) => {
        emptyFile.value = file.size === 0;
    };

    const parseXMLString = (data: string) => {
        let parsedXML = null;
        const addAt = (attrName: string) => {
            return `@${attrName}`;
        };
        parseString(
            data,
            {
                attrkey: '@',
                charkey: '$',
                explicitCharkey: false,
                trim: true,
                emptyTag: {},
                explicitArray: false,
                mergeAttrs: true,
                attrNameProcessors: [addAt],
                attrValueProcessors: [processors.parseNumbers, processors.parseBooleans],
                valueProcessors: [processors.parseNumbers, processors.parseBooleans],
            },
            (err, result) => {
                if (!err) parsedXML = result;
            },
        );
        return parsedXML;
    };

    /**
     * Parses an XML file and crops its data to a specific size if required
     * @param file file under parsing
     */
    const parseXML = async (file: any) => {
        if (!file) return;
        const data = await file.text();
        const parsedXML = parseXMLString(data);
        if (parsedXML) {
            const parsedSample = reduceSampleValues(parsedXML);
            emit('sample-uploaded', parsedSample);
            emit('parsed-sample-uploaded', parsedSample);
        }
    };

    const findArrayPath = (obj: any, key: string, separator = '||'): string[] | null => {
        if (R.is(Array, obj)) {
            return [key];
        }
        if (R.is(Object, obj)) {
            const keys = Object.keys(obj);
            if (keys.length > 0) {
                let subArrays: string[] = [];
                for (let k = 0; k < keys.length; k++) {
                    const subKey = keys[k];
                    const subArray = findArrayPath(obj[subKey], `${key}${separator}${subKey}`);
                    if (subArray) {
                        subArrays = [...subArrays, ...subArray];
                    }
                }
                return subArrays;
            }
        }
        return null;
    };

    const clearFiles = () => {
        emit('files-changed', { sample: null });
    };

    /**
     * Parses a CSV file.
     * If validation is set to true, it checks for duplicate headers or errors. If any, it stops and an error message is appeared.
     * @param file CSV file
     * @param validation Whether or not to validate the CSV data
     * @param records Return a specific number of records from the CSV data
     */
    const parseCSV = (file: any, validation = true, records: number | null = null) => {
        const CHUNK_SIZE = 1024 * 1024 * 5; // 5MB
        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
        if (totalChunks > 50) showValidationBar.value = true; // show validation progress bar only on large files
        let chunk = 0;

        return new Promise<any>((resolve) => {
            Papa.parse(file, {
                header: true,
                preview: records || null,
                chunkSize: !records ? CHUNK_SIZE : null,
                skipEmptyLines: true,
                dynamicTyping: !!records,
                transform: (value: any) => (records ? value.trim() : value),
                chunk: (results: any) => {
                    chunk += 1;
                    noCSVData.value = chunk === 1 && results.data.length === 0;
                    if (records) resolve(results);
                    if (validation) {
                        valPercentage.value = Math.ceil((chunk / totalChunks) * 100);

                        // check if there are any duplicate headers
                        const headers = results.meta.fields;
                        const uniqueHeaders = new Set(headers); // keep only the unique headers

                        duplicateHeaders.value = headers.length !== uniqueHeaders.size;
                        emptyFile.value = noCSVData.value && results.errors.length > 0;
                        invalidFormat.value = !!results.errors.length; // check if there are any errors, thus invalid file format
                        if (emptyFile.value || invalidFormat.value || duplicateHeaders.value || chunk === totalChunks) {
                            resolve(null);
                        }
                    }
                },
            } as any);
        });
    };

    const acceptedFiles = computed(() => {
        switch (fileType.value) {
            case 'csv':
                return '.csv,.tsv';
            case 'json':
                return '.json';
            case 'xml':
                return '.xml';
            case 'parquet':
                return '.parquet';
            default:
                return '.*';
        }
    });

    const checkInvalidJSON = async (file: any) => {
        if (!file) return;
        const data = await file.text();
        emptyFile.value = checkEmptyJSON(data);
        try {
            const json = JSON.parse(data);
            invalidFormat.value = R.type(json) !== 'Array' && R.type(json) !== 'Object';
        } catch (e) {
            invalidFormat.value = true;
        }
    };

    const checkInvalidCSV = async (file: any) => {
        if (!file) return;
        await emit('set-loading', true);
        await parseCSV(file);
        await emit('set-loading', false);
        validating.value = false;
        showValidationBar.value = false;
        valPercentage.value = 0;
    };

    return {
        changeFinalSample,
        limitResponse,
        parseJSON,
        checkInvalidXML,
        parseXML,
        parseXMLString,
        findArrayPath,
        clearFiles,
        reduceSampleValues,
        checkEmptyJSON,
        showValidationBar,
        noCSVData,
        invalidFormat,
        rangeError,
        emptyFile,
        valPercentage,
        acceptedFiles,
        parseCSV,
        checkInvalidJSON,
        checkInvalidCSV,
        checkInvalidParquet,
        duplicateHeaders,
    };
}
