import { FacetFilter, FacetFilterType, useFacetsFilters, useFeatureFlags } from '@/app/composable';
import { computed } from '@vue/composition-api';

export function useDataCheckinFilters(get: any) {
    const { reducedFacetsFilters, defaultValue } = useFacetsFilters();
    const { isEnabled, areAnyEnabled } = useFeatureFlags();

    const status = {
        label: 'Status',
        type: FacetFilterType.Checkbox,
        default: defaultValue(get, 'status'),
        options: [
            { value: 'completed', label: 'Completed' },
            { value: 'failed', label: 'Failed' },
            { value: 'configuration', label: 'Configuration' },
            { value: 'suspended', label: 'Suspended' },
            { value: 'idle', label: 'Idle' },
            { value: 'pending', label: 'Pending' },
            { value: 'running', label: 'Running' },
            { value: 'queued', label: 'Queued' },
            { value: 'update', label: 'Update' },
            { value: 'cancelled', label: 'Cancelled' },
        ],
    };

    const schedulingOption = {
        label: 'Scheduling Option',
        type: FacetFilterType.Checkbox,
        default: defaultValue(get, 'schedulingOption'),
        options: [
            { value: 'realTime', label: 'Real-Time' },
            { value: 'active', label: 'Active Schedule' },
            { value: 'expired', label: 'Schedule Expired' },
            { value: 'future', label: 'Future Execution' },
            { value: 'no', label: 'No Schedule' },
        ],
        tooltip:
            "Filter the data check-in pipelines for which an explicit schedule has been set. Only pipelines with Harvesting Option: Large Files, Data Provider API, Platform Kafka, Data Provider's Kafka, Platform MQTT, Data Provider's MQTT, are displayed.",
    };

    const step = computed(() => {
        const options = [
            { value: 'mapping', label: 'Mapping' },
            { value: 'cleaning', label: 'Cleaning' },
        ];
        if (isEnabled('anonymisation')) options.push({ value: 'anonymiser', label: 'Anonymisation' });
        if (isEnabled('encryption')) options.push({ value: 'encryption', label: 'Encryption' });
        return {
            label: 'With Step',
            type: FacetFilterType.Checkbox,
            default: defaultValue(get, 'step'),
            options,
        };
    });

    const harvestingOption = computed(() => {
        const options = [{ value: 'file', label: 'File' }];
        if (isEnabled('harvester.large-files')) options.push({ value: 'largeFiles', label: 'Large Files' });
        if (isEnabled('harvester.api')) options.push({ value: 'api', label: 'Data Provider API' });
        if (isEnabled('harvester.platform-api')) options.push({ value: 'internalApi', label: 'Platform API' });
        if (isEnabled('harvester.platform-kafka')) options.push({ value: 'kafka', label: 'Platform Kafka' });
        if (isEnabled('harvester.kafka')) options.push({ value: 'externalKafka', label: `Data Provider's Kafka` });
        if (isEnabled('harvester.platform-mqtt')) options.push({ value: 'mqtt', label: 'Platform MQTT' });
        if (isEnabled('harvester.mqtt')) options.push({ value: 'externalMqtt', label: `Data Provider's MQTT` });

        return {
            label: 'Harvesting Option',
            type: FacetFilterType.Checkbox,
            default: defaultValue(get, 'harvestingOption'),
            options,
        };
    });

    const location = computed(() => {
        const options = [{ value: 'cloud', label: 'Centralized Data Space' }];
        if (isEnabled('on-premise')) options.push({ value: 'onPremise', label: 'Federated Data Space' });
        const locationFilter = {
            label: 'Execution Location',
            type: FacetFilterType.Checkbox,
            default: defaultValue(get, 'location'),
            options,
        };

        return locationFilter;
    });

    const scheduleHorizon = {
        label: 'Schedule Horizon',
        type: FacetFilterType.Date,
        default: {
            min: defaultValue(get, 'scheduleHorizonMin', false),
            max: defaultValue(get, 'scheduleHorizonMax', false),
        },
        tooltip: 'The time period within there are active schedules',
    };

    const dateCreated = {
        label: 'Date Created Range',
        type: FacetFilterType.Date,
        default: {
            min: defaultValue(get, 'dateCreatedMin', false),
            max: defaultValue(get, 'dateCreatedMax', false),
        },
    };

    const dateUpdated = {
        label: 'Date Updated Range',
        type: FacetFilterType.Date,
        default: {
            min: defaultValue(get, 'dateUpdatedMin', false),
            max: defaultValue(get, 'dateUpdatedMax', false),
        },
    };

    const user = {
        // displayed only to "shared" pipelines tab
        label: 'User',
        type: FacetFilterType.Treeselect,
        placeholder: 'Select user',
        default: defaultValue(get, 'user'),
        options: [],
    };

    const pipeline = {
        label: 'Pipeline ID',
        placeholder: 'Enter pipeline ID',
        type: FacetFilterType.Text,
        default: { id: defaultValue(get, 'pipeline', false) },
    };

    const dcjFilters = computed(
        (): Record<string, FacetFilter> => {
            const all = {
                pipeline,
                status,
                step: step.value,
                harvestingOption: harvestingOption.value,
                location: location.value,
                dateCreated,
                dateUpdated,
                user,
            };
            if (
                areAnyEnabled([
                    'harvester.api',
                    'harvester.kafka',
                    'harvester.platform-kafka',
                    'harvester.mqtt',
                    'harvester.platform-mqtt',
                    'harvester.large-files',
                ])
            )
                all['schedulingOption'] = schedulingOption;

            if (
                areAnyEnabled(['harvester.api', 'harvester.kafka', 'harvester.platform-kafka', 'harvester.large-files'])
            )
                all['scheduleHorizon'] = scheduleHorizon;

            return all;
        },
    );

    const reducedDcjFilters: any = reducedFacetsFilters(dcjFilters);

    return { dcjFilters, reducedDcjFilters };
}
