





















































































































import { FormBlock, ButtonGroup, ConfirmModal } from '@/app/components';
import Schedules from '../views/harvester/external-api/Schedules.vue';
import store from '@/app/store';
import { useSchedule } from '@/app/composable';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import { ScheduleFrequency } from '@/modules/workflow-designer/constants';
import { ProcessingOptions } from '@/modules/data-checkin/constants';
import { ScheduleType } from '@/modules/workflow-designer/types';

export default defineComponent({
    name: 'ProcessingOptions',
    components: {
        FormBlock,
        ValidationProvider,
        Schedules,
        ButtonGroup,
        ConfirmModal,
    },
    props: {
        processingOptions: {
            type: Array,
            default: () => [ProcessingOptions.RealTime, ProcessingOptions.TimeBased],
        },
        configuration: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        isDeprecated: {
            type: Boolean,
            default: false,
        },
        workflowId: {
            type: String,
            required: true,
        },
        jobCreatedById: {
            type: Number,
        },
        pipelineFinalized: {
            type: Boolean,
            required: true,
        },
        stepId: {
            type: Number,
            required: true,
        },
        isFinalized: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const frequency = ref<any>(null);
        const newFrequency = ref<ScheduleFrequency | null>(null);
        const loadingSchedules = ref(false);
        const showChangeFrequencyModal = ref(false);
        const processing = computed<string>(() => props.configuration.processing);
        const newProcessingOption = ref<ProcessingOptions | null>(null);
        const showChangeProcessingOptionModal = ref(false);
        const user = computed(() => store.state.auth.user);
        const schedules = ref<any>([]);
        const { deleteSchedules } = useSchedule();
        const updateSchedules = (updatedSchedules: ScheduleType[]) => {
            schedules.value = updatedSchedules;
            emit('update-schedules', updatedSchedules);

            if (schedules.value.length) {
                if (schedules.value.find((schedule: ScheduleType) => schedule.frequency === ScheduleFrequency.Hourly))
                    frequency.value = ScheduleFrequency.Hourly;
                if (schedules.value.find((schedule: ScheduleType) => schedule.frequency === ScheduleFrequency.Daily))
                    frequency.value = ScheduleFrequency.Daily;
            } else frequency.value = chosenFrequency.value;
        };

        const schedulesPerFrequency = computed(() => (chosenFrequency.value === ScheduleFrequency.Hourly ? 1 : 12));

        const chosenFrequency = computed(() => {
            if (frequency.value) return frequency.value;
            return ScheduleFrequency.Hourly;
        });

        const changeFrequency = (newFreq: ScheduleFrequency) => {
            frequency.value = newFreq;
            showChangeFrequencyModal.value = false;
            newFrequency.value = null;
        };

        const checkChangeFrequency = (newFreq: ScheduleFrequency) => {
            if (schedules.value.length) {
                showChangeFrequencyModal.value = true;
                newFrequency.value = newFreq;
            } else {
                changeFrequency(newFreq);
            }
        };

        const changeProcessingOption = async (newProcOption: ProcessingOptions) => {
            if (schedules.value.length) {
                await deleteSchedules(schedules.value.map((schedule: { id: string }) => schedule.id));
                schedules.value.splice(0);
            }
            emit('update-processing-option', newProcOption);
            showChangeProcessingOptionModal.value = false;
            newProcessingOption.value = null;
        };

        const checkChangeProcessingOption = (event: any) => {
            const newProcOption = event.target.value;
            if (schedules.value.length) {
                showChangeProcessingOptionModal.value = true;
                newProcessingOption.value = newProcOption;
            } else {
                changeProcessingOption(newProcOption);
            }
        };

        return {
            user,
            updateSchedules,
            schedules,
            changeFrequency,
            schedulesPerFrequency,
            showChangeFrequencyModal,
            newFrequency,
            checkChangeFrequency,
            chosenFrequency,
            showChangeProcessingOptionModal,
            newProcessingOption,
            checkChangeProcessingOption,
            changeProcessingOption,
            processing,
            ProcessingOptions,
            loadingSchedules,
            ScheduleFrequency,
        };
    },
});
