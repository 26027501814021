

































































import { FormBlock } from '@/app/components';
import { offlineRetentionOptions, otherFilesRetentionOptions } from '@/modules/asset/config/asset';
import { ContractStatus, ExpectedUsageText } from '@/modules/sharing/constants';
import { PropType, computed, defineComponent } from '@vue/composition-api';
import HtmlDiff from 'htmldiff-js';
import { useContractMetadata } from '../../composable';
import { Contract, ContractTransaction } from '../../types';
import LicenseDetail from './LicenseDetail.vue';

export default defineComponent({
    name: 'LicenseDetails',
    components: {
        FormBlock,
        LicenseDetail,
    },
    props: {
        contract: {
            type: Object as PropType<Contract>,
            required: true,
        },
        onEditMode: {
            type: Boolean,
            default: false,
        },
        previousNegotiation: {
            type: Object as PropType<ContractTransaction>,
            default: () => ({}),
        },
        isDerivation: {
            type: Boolean,
            default: false,
        },
        assetTypeId: {
            type: Number,
            default: 1,
        },
        hasUpdatingContract: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { getLicensingSchema, getExpectedUpdatesSchema } = useContractMetadata(props.contract);

        const getHtmlList = (texts: string[]) => {
            if (texts) {
                const result = ['<ul>'];
                texts.forEach((text: string) => {
                    result.push(`<li>${text}</li>`);
                });
                result.push('</ul>');
                return result.join('');
            }
            return null;
        };

        const expectedUsage = computed(() => {
            if (!props.contract.metadata.license.expectedUsage) return null;
            const options = Object.values(ExpectedUsageText);
            const newValues = props.contract.metadata.license.expectedUsage.map(
                (value: string) => ExpectedUsageText[value],
            );
            if (
                props.contract.status === ContractStatus.Negotiate &&
                props.previousNegotiation?.changes.metadata?.license?.expectedUsage
            ) {
                const oldValues = props.previousNegotiation.changes.metadata.license.expectedUsage.map(
                    (value: string) => ExpectedUsageText[value],
                );
                return { oldValues, newValues, options };
            }
            return { oldValues: null, newValues, options };
        });

        const offlineRetention = computed(() => {
            if (!props.contract.metadata.license.offlineRetention) return null;
            const options =
                props.contract?.asset?.structure?.type === 'other'
                    ? otherFilesRetentionOptions
                    : offlineRetentionOptions;
            const newValues = props.contract.metadata.license.offlineRetention;
            if (
                props.contract.status === ContractStatus.Negotiate &&
                props.previousNegotiation?.changes.metadata?.license?.offlineRetention
            ) {
                const oldValues = props.previousNegotiation.changes.metadata.license.offlineRetention;
                return { oldValues, newValues, options };
            }
            return { oldValues: null, newValues, options };
        });

        const expectedUpdates = computed(() => {
            if (!props.contract.metadata.license.expectedUpdates?.unit) return null;
            const currentExpectedUpdatesHtml = getHtmlList([
                `${props.contract.metadata.license.expectedUpdates?.value ?? ''} ${
                    props.contract.metadata.license.expectedUpdates?.unit
                }`,
            ]);
            if (
                props.contract.status === ContractStatus.Negotiate &&
                props.previousNegotiation?.changes.metadata?.license?.expectedUpdates
            ) {
                const previousExpectedUpdatesHtml = getHtmlList([
                    `${props.previousNegotiation.changes.metadata.license.expectedUpdates.value ?? ''} ${
                        props.previousNegotiation.changes.metadata.license.expectedUpdates.unit
                    }`,
                ]);
                return HtmlDiff.execute(previousExpectedUpdatesHtml, currentExpectedUpdatesHtml);
            }
            return currentExpectedUpdatesHtml;
        });

        return {
            getLicensingSchema,
            getExpectedUpdatesSchema,
            ContractStatus,
            expectedUsage,
            offlineRetention,
            expectedUpdates,
        };
    },
});
