



































































































































































































































































































import { SortAscendingIcon, SortDescendingIcon } from '@vue-hero-icons/outline';
import { ClipboardCopyIcon } from '@vue-hero-icons/solid';
import { defineComponent, PropType, ref, useSlots } from '@vue/composition-api';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { SelfBuildingSquareSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { Pagination, Scrollbar, SvgImage } from '.';
import { useFilters } from '../composable';
import { ColumnPosition, ColumnType } from '../constants';

dayjs.extend(relativeTime);

interface Column {
    label: string;
    key: string;
    type: ColumnType;
    classes?: string;
    showAfter?: string;
    position?: ColumnPosition;
    copy?: boolean | any;
    sortable?: boolean | { enabled: boolean; note?: string; key?: string };
    relativeTime?: boolean;
    formatInUtc?: boolean;
}

export default defineComponent({
    name: 'TailwindTable',
    props: {
        columns: { type: Array as PropType<Column[]>, default: () => [] },
        rows: { type: Array, default: () => [] },
        keyField: { type: String, default: 'id' },
        headClasses: { type: String, default: 'text-white bg-primary-800' },
        pagination: { type: Object as PropType<{ page: number; pageSize: number; total: number }>, required: false },
        rowLink: {
            type: [Function, String],
            default: () => {
                return null;
            },
        },
        loading: { type: Boolean, default: false },
        sortBy: { type: Object as PropType<{ field: string; asc: boolean }>, required: false },
        narrow: { type: Boolean, default: false },
        isLight: { type: Boolean, default: false },
        striped: { type: Boolean, default: false },
    },
    components: {
        Pagination,
        Scrollbar,
        SvgImage,
        SelfBuildingSquareSpinner,
        ClipboardCopyIcon,
        SortAscendingIcon,
        SortDescendingIcon,
    },
    setup(props, { root }) {
        const { fromNow: dateFromNow, formatDateAs: formatDate, formatDateStringAsIs } = useFilters();
        const valueToCopy = ref<any>(null);
        const tableUid = uuidv4();
        const slots = useSlots();

        const formatShortUid = (value: string) => {
            const uid: string = R.trim(value);
            return !R.isEmpty(uid) && uid.split('-').length >= 0 ? uid.split('-')[0] : uid;
        };
        const formatLabel = (column: Column, value: any) => {
            if (R.isNil(value)) return null;
            switch (column.type) {
                case ColumnType.Uid: {
                    return formatShortUid(value);
                }
                case ColumnType.Integer:
                    return parseInt(value, 10);
                case ColumnType.Double:
                case ColumnType.Number:
                    if (value === '+Inf' || value === '-Inf') return value;
                    return parseFloat(value);
                case ColumnType.Date:
                    if (value === 'NaT') return null;
                    if (column.relativeTime) return dateFromNow(value, true);
                    else if (column.formatInUtc) return formatDate(value, true, 'DD/MM/YY', 'UTC');
                    else return formatDateStringAsIs(value);
                case ColumnType.Datetime:
                    if (value === 'NaT') return null;
                    if (column.relativeTime) return dateFromNow(value, true);
                    else if (column.formatInUtc) return formatDate(value, true, 'DD/MM/YY HH:mm:ss', 'UTC');
                    else return formatDateStringAsIs(value);
                default: {
                    if (R.is(String, value)) {
                        const trimmed = R.trim(value);
                        if (R.isEmpty(trimmed)) return null;
                        return trimmed;
                    }
                    return value;
                }
            }
        };

        const formatTooltip = (column: Column, value: any) => {
            if (R.isNil(value)) return null;

            switch (column.type) {
                case ColumnType.Uid:
                    return R.trim(value);
                case ColumnType.Date:
                    if (value === 'NaT') return null;
                    return column.formatInUtc
                        ? formatDate(value, true, 'DD/MM/YY', 'UTC')
                        : formatDateStringAsIs(value);
                case ColumnType.Datetime:
                    if (value === 'NaT') return null;
                    return column.formatInUtc
                        ? formatDate(value, true, 'DD/MM/YY HH:mm:ss', 'UTC')
                        : formatDateStringAsIs(value);
                default:
                    return formatLabel(column, value);
            }
        };

        const position = (column: Column): ColumnPosition => {
            if (column.position) return column.position;
            switch (column.type) {
                case ColumnType.Uid:
                case ColumnType.Integer:
                case ColumnType.Double:
                    return ColumnPosition.Center;
                default:
                    return ColumnPosition.Left;
            }
        };

        const formatCopy = (column: Column, value: any, row: any) => {
            if (R.isNil(value) || !column.copy) return null;
            if (R.has('copy', column) && R.is(Function, column.copy)) return column.copy(value, row);

            switch (column.type) {
                case ColumnType.Uid:
                case ColumnType.String:
                case ColumnType.Text:
                    return R.trim(value);
                default:
                    return value;
            }
        };

        const copy = (inputName: string) => {
            valueToCopy.value = document.querySelector(`[data-id="${tableUid}"] td input[data-id="${inputName}"]`);
            if (valueToCopy.value.value) {
                valueToCopy.value.setAttribute('type', 'text');
                valueToCopy.value.select();
                document.execCommand('copy');
                valueToCopy.value.setAttribute('type', 'hidden');
            }
        };

        const columnAdditionalClasses = (column: Column) => {
            if (R.has('classes', column)) return column.classes;
            switch (column.type) {
                case ColumnType.Text:
                    return 'max-w-56';
                case ColumnType.Uid:
                    return 'text-center flex flex-col items-center px-auto  rounded-md text-sm font-medium';
                default:
                    return '';
            }
        };

        const columnName = (column: Column) => {
            if (Array.isArray(column.key)) return column.key.join('-');
            return column.key;
        };

        const columnValue = (row: any, column: Column) => {
            if (R.is(Array, column.key)) return R.path(column.key as string[], row);
            return row[column.key];
        };

        const columnLabel = (column: Column) => {
            if (Array.isArray(column.label)) return column.label.join('-');
            return column.label;
        };

        const isSortable = (column: Column) => {
            if (
                !R.isNil(column.sortable) &&
                ((R.is(Boolean, column.sortable) && !!column.sortable) ||
                    (R.is(Object, column.sortable) && !!(column.sortable as any).enabled))
            )
                return true;
            return false;
        };

        const sortNote = (column: Column) => {
            if (!R.isNil(column.sortable) && R.is(Object, column.sortable) && !R.isNil((column.sortable as any).note))
                return (column.sortable as any).note;
            return undefined;
        };

        const sortKey = (column: Column) => {
            if (!R.isNil(column.sortable) && R.is(Object, column.sortable) && !R.isNil((column.sortable as any).key))
                return (column.sortable as any).key;
            return column.key;
        };

        const cellClicked = (link: any, value: any, row: any) => {
            if (!link) return;
            let processedLink: string;
            if (!R.is(String, link)) processedLink = link(value, row);
            else processedLink = link;

            if (processedLink) {
                root.$router.push(processedLink);
            }
        };

        const rowLinkValue = (row: any) => {
            if (!props.rowLink) return;
            let processedLink: string;
            if (R.is(Function, props.rowLink)) processedLink = props.rowLink(row);
            else processedLink = props.rowLink;

            return processedLink;
        };

        const rowClicked = (row: any) => {
            const processedLink: any = rowLinkValue(row);

            if (processedLink) {
                root.$router.push(processedLink);
            }
        };

        return {
            tableUid,
            ColumnPosition,
            columnAdditionalClasses,
            formatLabel,
            formatTooltip,
            formatCopy,
            columnName,
            columnValue,
            columnLabel,
            uuidv4,
            formatShortUid,
            cellClicked,
            rowLinkValue,
            rowClicked,
            position,
            copy,
            isSortable,
            sortKey,
            sortNote,
            slots,
        };
    },
});
