



































































































































































































































































































































import { OnClickOutside, ShortTypeBadge, TwButton, VariableAwareInput, DropdownMenu } from '@/app/components';
import { S } from '@/app/utilities';
import {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    MenuIcon,
    SearchIcon,
    XIcon,
    CheckIcon,
    TrashIcon,
    PlusCircleIcon,
    MenuAlt3Icon,
} from '@vue-hero-icons/outline';
import { computed, defineComponent, PropType, reactive, ref, watch } from '@vue/composition-api';
import { ascend, clone, descend, intersection, isEmpty, prop, sortWith } from 'ramda';
import Draggable from 'vuedraggable';
import { useVariableUtils } from '../composable';
import { VariableType } from '../constants/variable-type.constants';
import { Variable } from '../interfaces/variable.interface';
import { v4 as uuidv4 } from 'uuid';
import { useDataType } from '@/modules/workflow-designer/composable/data-type';
import Pagination from './Pagination.vue';

interface Column {
    label: string;
    type: string;
    value: string;
    selectable?: boolean;
    custom?: boolean;
}

export default defineComponent({
    name: 'AdvancedOrderedSelect',
    model: {
        prop: 'columns',
        event: 'update-columns',
    },
    components: {
        TwButton,
        Draggable,
        ChevronLeftIcon,
        ChevronRightIcon,
        MenuIcon,
        SearchIcon,
        ChevronDoubleRightIcon,
        ChevronDoubleLeftIcon,
        ShortTypeBadge,
        XIcon,
        OnClickOutside,
        PlusCircleIcon,
        VariableAwareInput,
        CheckIcon,
        TrashIcon,
        DropdownMenu,
        MenuAlt3Icon,
        Pagination,
    },
    props: {
        columns: {
            type: Object as PropType<{ selected: Column[]; unselected: Column[]; structure: Column[] }>,
            default: () => ({ selected: [], unselected: [], structure: [] }),
        },
        itemName: {
            type: String,
            default: 'column',
        },
        selectedText: {
            type: String,
            default: 'selected',
        },
        unselectedText: {
            type: String,
            default: 'unselected',
        },
        requireSelection: {
            type: Boolean,
            default: true,
        },
        restrictToOnlySameType: {
            type: Boolean,
            default: false,
        },
        restrictToTypes: { type: Array as PropType<string[]>, required: false },
        customColumnEnabled: {
            type: Boolean,
            default: false,
        },
        customColumnPlaceholder: {
            type: String,
        },
        customColumnAvailableVariables: {
            type: Object as PropType<Record<string, Variable>>,
            default: () => {
                return {};
            },
        },
    },
    setup(props, { emit }) {
        const { getDisplayValue, getMatchedVariables } = useVariableUtils();
        const { getLabel, getTextColor, getBgColor, getDataTypes } = useDataType();

        const pageSize = 100;
        const availablePage = ref(1);
        const selectedPage = ref(1);

        const notSelectedColumns = ref<Column[]>(clone(props.columns.unselected));
        const searchSelectedColumns = ref<string | null>(null);
        const searchNotSelectedColumns = ref<string | null>(null);

        const addCustomMode = ref<boolean>(false);
        const customValue = ref<string | null>(null);

        const structureDFColumns = ref<Column[]>(clone(props.columns.structure));
        const currentSort = ref<string | null>('initial');
        const sortingOptions = ref([
            {
                name: 'Current DataFrame Order',
                key: 'initial',
            },
            {
                name: 'Reverse DataFrame Order',
                key: 'reverse',
            },
            {
                name: 'Alphabetical Order (A-Z)',
                key: 'ascend',
            },
            {
                name: 'Reverse Alphabetical Order (Z-A)',
                key: 'descend',
            },
        ]);

        const selectedColumnTypes = computed(() => {
            // if we want to restrict by specific types then we just take those
            if (props.restrictToTypes) return props.restrictToTypes;
            // if we want to restrict by the same type then we check the type of the first selected
            if (props.restrictToOnlySameType && selectedColumns.value.length > 0)
                return [selectedColumns.value[0].type];
            // if we want to restrict from the same type and didn't match the previous
            // then we check if we have searched not selected columns and if they all have the same type
            // we set that as the restricted list of types
            if (
                props.restrictToOnlySameType &&
                searchedNotSelectedColumns.value.length > 0 &&
                searchedNotSelectedColumns.value.every(
                    (c: Column) => c.type === searchedNotSelectedColumns.value[0].type,
                )
            ) {
                return [searchedNotSelectedColumns.value[0].type];
            }

            // in all other cases there is no column types selected
            return [];
        });

        const SideOption = {
            Selected: 'selected',
            Unselected: 'unselected',
        };

        const shiftSelection: any = reactive({
            start: -1,
            end: -1,
            side: '',
        });

        const getVariableColumn = (column: Column) => {
            if (column.custom) {
                const matchedVariables = getMatchedVariables(column.value, props.customColumnAvailableVariables);
                const displayValue = getDisplayValue(column.value, matchedVariables);
                column.label = displayValue;
            }

            return column;
        };

        const selectedColumns = ref<Column[]>(
            clone(props.columns.selected).map((column: Column) => getVariableColumn(column)),
        );

        const updateColumns = () => {
            selectedColumns.value = selectedColumns.value.map((col: Column) => {
                return { ...col, selectable: true };
            });

            notSelectedColumns.value = notSelectedColumns.value.map((col: Column) => {
                const supportedDataTypes = getDataTypes(col.type);
                return {
                    ...col,
                    selectable:
                        isEmpty(selectedColumnTypes.value) ||
                        intersection(selectedColumnTypes.value, supportedDataTypes).length > 0,
                };
            });
            emit('update-columns', { selected: selectedColumns.value, unselected: notSelectedColumns.value });
        };

        const changeSelection = (column: Column, selected: boolean) => {
            if (column.selectable === false) {
                return;
            }

            resetShiftSelection();

            if (selected) {
                if (column.custom) selectedColumns.value.unshift(column);
                else selectedColumns.value.push(column);
                notSelectedColumns.value = notSelectedColumns.value.filter((c: Column) => c !== column);
            } else {
                selectedColumns.value = selectedColumns.value.filter((c: Column) => c !== column);

                if (!column.custom) notSelectedColumns.value.push(column);
                currentSort.value = null;
            }

            updateColumns();
        };

        const searchedNotSelectedColumns = computed(() => {
            return !searchNotSelectedColumns.value || searchNotSelectedColumns.value.length === 0
                ? notSelectedColumns.value
                : notSelectedColumns.value.filter((column: any) =>
                      column.label.toLowerCase().includes(searchNotSelectedColumns.value?.toLowerCase()),
                  );
        });

        const visibleNotSelectedColumns = computed(() => {
            return searchedNotSelectedColumns.value.slice(
                (availablePage.value - 1) * pageSize,
                (availablePage.value - 1) * pageSize + pageSize,
            );
        });

        const searchedSelectedColumns = computed(() => {
            return !searchSelectedColumns.value || searchSelectedColumns.value.length === 0
                ? selectedColumns.value
                : selectedColumns.value.filter((column: any) =>
                      column.label.toLowerCase().includes(searchSelectedColumns.value?.toLowerCase()),
                  );
        });

        const visibleSelectedColumns = computed(() => {
            return searchedSelectedColumns.value.slice(
                (selectedPage.value - 1) * pageSize,
                (selectedPage.value - 1) * pageSize + pageSize,
            );
        });

        const moveToUnselected = () => {
            const searchedColumns =
                shiftSelectionIndices.value.length && shiftSelection.side === SideOption.Selected
                    ? searchedSelectedColumns.value.slice(
                          ...getSliceIndices(
                              shiftSelectionIndices.value.length == 1
                                  ? shiftSelectionIndices.value[0]
                                  : shiftSelection.start,
                              shiftSelectionIndices.value.length == 1
                                  ? shiftSelectionIndices.value[0]
                                  : shiftSelection.end,
                          ),
                      )
                    : searchedSelectedColumns.value;
            resetShiftSelection();

            const searchedColumnValues = searchedColumns.map((column: Column) => column.value);
            selectedColumns.value = selectedColumns.value.filter(
                (column: Column) => !searchedColumnValues.includes(column.value),
            );
            notSelectedColumns.value = notSelectedColumns.value.concat(searchedColumns);

            searchSelectedColumns.value = null;
            updateColumns();
            currentSort.value = null;
        };

        const moveToSelected = () => {
            let searchedColumns =
                shiftSelectionIndices.value.length && shiftSelection.side === SideOption.Unselected
                    ? searchedNotSelectedColumns.value.slice(
                          ...getSliceIndices(
                              shiftSelectionIndices.value.length == 1
                                  ? shiftSelectionIndices.value[0]
                                  : shiftSelection.start,
                              shiftSelectionIndices.value.length == 1
                                  ? shiftSelectionIndices.value[0]
                                  : shiftSelection.end,
                          ),
                      )
                    : searchedNotSelectedColumns.value;
            resetShiftSelection();

            searchedColumns = searchedColumns.filter((c: Column) => c.selectable);

            const searchedColumnValues = searchedColumns.map((column: Column) => column.value);
            notSelectedColumns.value = notSelectedColumns.value.filter(
                (column: Column) => !searchedColumnValues.includes(column.value),
            );
            selectedColumns.value = selectedColumns.value.concat(searchedColumns);
            searchNotSelectedColumns.value = null;

            updateColumns();
        };

        const noSelectedText = computed(() =>
            props.requireSelection
                ? `At least 1 ${props.itemName} must be ${props.selectedText}`
                : `No ${props.selectedText} ${props.itemName}s`,
        );

        const getShiftSelectionCount = (side: string) =>
            side === shiftSelection.side
                ? shiftSelectionIndices.value.length
                : side === SideOption.Unselected
                ? searchedNotSelectedColumns.value.length
                : searchedSelectedColumns.value.length;

        const shiftSelectionIndices = computed(() => {
            const startIndex = shiftSelection.start;
            const endIndex = shiftSelection.end;
            if (startIndex === -1) return [];
            if (endIndex === -1) return [startIndex];

            let range = Array(Math.abs(endIndex - startIndex) + 1)
                .fill(0)
                .map((_: any, idx: number) => Math.min(startIndex, endIndex) + idx);

            if (shiftSelection.side === SideOption.Unselected && searchedNotSelectedColumns.value.length) {
                range = range.filter((idx: number) => searchedNotSelectedColumns.value[idx].selectable);
            }

            return range;
        });

        const getSliceIndices = (start: number, end: number) => (start > end ? [end, start + 1] : [start, end + 1]);

        const moveToSelectedTooltip = computed(() => {
            let count = getShiftSelectionCount(SideOption.Unselected);

            if (!shiftSelection.side && searchedNotSelectedColumns.value.length) {
                count -= searchedNotSelectedColumns.value.filter((c: Column) => c.selectable === false).length;
            }

            return count
                ? `Move ${count} ${props.unselectedText} ${S.sanitizeHtml(props.itemName)}${
                      count > 1 ? 's' : ''
                  } to ${S.capitalizeFirstLetter(props.selectedText)} from the Table View`
                : null;
        });

        const moveToUnselectedTooltip = computed(() => {
            const count = getShiftSelectionCount(SideOption.Selected);
            return count
                ? `Move ${count} ${props.selectedText} ${S.sanitizeHtml(props.itemName)}${
                      count > 1 ? 's' : ''
                  } to ${S.capitalizeFirstLetter(props.unselectedText)} from the Table View`
                : null;
        });

        const shiftSelectionOffset = (side: 'selected' | 'unsselected') =>
            ((side === SideOption.Unselected ? availablePage.value : selectedPage.value) - 1) * pageSize;

        /**
         * Event handler for shift and click columns selection
         */
        const onClickWithShift = (idx: number, side: 'selected' | 'unsselected') => {
            // initializing the active side of selection e.g. selected, unselected column
            shiftSelection.side = side;

            // abort multi selection in case there are not available selectable columns in unselected side
            if (
                side === SideOption.Unselected &&
                searchedNotSelectedColumns.value.length &&
                searchedNotSelectedColumns.value[idx].selectable === false
            )
                return;

            // initialize start index of selection e.g. the first ever click start column index
            if (shiftSelection.start === -1) {
                shiftSelection.start = idx + shiftSelectionOffset(side);
                return;
            }

            // set end index. In case there is a start index, the next click event is the end index
            shiftSelection.end = idx + shiftSelectionOffset(side);
        };

        const onClickOutsideEvent = (side: string) => {
            if (shiftSelection.side !== side) return;
            resetShiftSelection();
        };

        const resetShiftSelection = () => {
            shiftSelection.side = '';
            shiftSelection.start = -1;
            shiftSelection.end = -1;
        };

        const addCustomVariable = () => (addCustomMode.value = true);

        const setCustom = () => {
            if (customValue.value) {
                const matchedVariables = getMatchedVariables(customValue.value, props.customColumnAvailableVariables);
                const displayValue = getDisplayValue(customValue.value, matchedVariables);

                changeSelection(
                    {
                        label: displayValue,
                        selectable: true,
                        type: VariableType.Unknown,
                        value: customValue.value,
                        custom: true,
                    } as any,
                    true,
                );
            }

            addCustomMode.value = false;
            customValue.value = null;
        };

        const cancelCustom = () => {
            addCustomMode.value = false;
            customValue.value = null;
        };

        const finishCustomEdit = () => setCustom();

        const applyColumnSorting = (option: string) => {
            currentSort.value = option;
            switch (option) {
                case 'initial':
                    notSelectedColumns.value = clone(structureDFColumns.value).filter((column: Column) =>
                        notSelectedColumns.value.find((c: Column) => c.value === column.value),
                    );
                    break;
                case 'reverse':
                    notSelectedColumns.value = notSelectedColumns.value.reverse();
                    break;
                case 'ascend':
                    notSelectedColumns.value = sortWith([ascend(prop('value'))])(notSelectedColumns.value) as Column[];
                    break;
                case 'descend':
                    notSelectedColumns.value = sortWith([descend(prop('value'))])(notSelectedColumns.value) as Column[];
                    break;
            }
        };

        const haveAllUnselectedColumnsSameType = computed(
            () =>
                searchedNotSelectedColumns.value.length &&
                searchedNotSelectedColumns.value.some((c: any) => selectedColumnTypes.value?.includes(c.type)),
        );

        const isMoveToSelectedDisabled = computed(
            () =>
                (props.restrictToOnlySameType && !haveAllUnselectedColumnsSameType.value) ||
                searchedNotSelectedColumns.value.length === 0 ||
                !notSelectedColumns.value.find((c: any) => c.selectable) ||
                !searchedNotSelectedColumns.value?.find((c: any) => c.selectable),
        );

        watch(
            () => props.columns,
            (columns: { selected: Column[]; unselected: Column[] }) => {
                selectedColumns.value = clone(columns.selected).map((column: any) => getVariableColumn(column));
                notSelectedColumns.value = clone(columns.unselected);
            },
        );

        watch(
            () => searchedNotSelectedColumns.value.length,
            (columnsLength: number) => {
                return (availablePage.value =
                    columnsLength <= (availablePage.value - 1) * pageSize &&
                    availablePage.value > Math.ceil(columnsLength / pageSize)
                        ? Math.ceil(columnsLength / pageSize) || 1
                        : availablePage.value);
            },
        );

        watch(
            () => searchedSelectedColumns.value.length,
            (columnsLength: number) => {
                return (selectedPage.value =
                    columnsLength <= (selectedPage.value - 1) * pageSize &&
                    selectedPage.value > Math.ceil(columnsLength / pageSize)
                        ? Math.ceil(columnsLength / pageSize) || 1
                        : selectedPage.value);
            },
        );

        updateColumns();

        return {
            S,
            selectedColumns,
            notSelectedColumns,
            searchSelectedColumns,
            searchNotSelectedColumns,
            searchedSelectedColumns,
            searchedNotSelectedColumns,
            updateColumns,
            changeSelection,
            moveToUnselected,
            moveToSelected,
            noSelectedText,
            onClickWithShift,
            shiftSelection,
            shiftSelectionIndices,
            onClickOutsideEvent,
            moveToSelectedTooltip,
            moveToUnselectedTooltip,
            SideOption,
            VariableType,
            addCustomVariable,
            addCustomMode,
            customValue,
            setCustom,
            cancelCustom,
            finishCustomEdit,
            selectedColumnTypes,
            uuidv4,
            getLabel,
            getBgColor,
            getTextColor,
            isMoveToSelectedDisabled,
            sortingOptions,
            currentSort,
            applyColumnSorting,
            availablePage,
            selectedPage,
            pageSize,
            visibleNotSelectedColumns,
            visibleSelectedColumns,
            shiftSelectionOffset,
        };
    },
});
