import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import * as R from 'ramda';

dayjs.extend(utc);

export function useDuration() {
    const SECOND = 1000;
    const MINUTE = 60 * SECOND;
    const HOUR = 60 * MINUTE;
    const DAY = 24 * HOUR;

    const dayjsObj = (value: Date, forceUtc: boolean = false) => {
        return forceUtc ? dayjs.utc(value) : dayjs(value);
    };

    const formatMilliseconds = (
        millis: number,
        labels: {
            day: { singular: string; plural: string };
            hour: { singular: string; plural: string };
            minute: { singular: string; plural: string };
            second: { singular: string; plural: string };
            millisecond: { singular: string; plural: string };
        } = {
            day: { singular: 'day', plural: 'days' },
            hour: { singular: 'hour', plural: 'hours' },
            minute: { singular: 'min', plural: 'mins' },
            second: { singular: 'sec', plural: 'secs' },
            millisecond: { singular: 'milli', plural: 'millis' },
        },
    ) => {
        if (R.isNil(millis) || !R.is(Number, millis)) throw Error(`Cannot format duration '${millis}'`);
        const days = Math.floor(millis / DAY);
        const hours = Math.floor((millis - days * DAY) / HOUR);
        const minutes = Math.floor((millis - days * DAY - hours * HOUR) / MINUTE);
        const seconds = Math.floor((millis - days * DAY - hours * HOUR - minutes * MINUTE) / SECOND);
        const milliseconds = millis - days * DAY - hours * HOUR - minutes * MINUTE - seconds * SECOND;

        const formatStrings = [];
        if (days > 0) formatStrings.push(`${days} ${days === 1 ? labels.day.singular : labels.day.plural}`);
        if (hours > 0) formatStrings.push(`${hours} ${hours === 1 ? labels.hour.singular : labels.hour.plural}`);
        if (minutes > 0)
            formatStrings.push(`${minutes} ${minutes === 1 ? labels.minute.singular : labels.minute.plural}`);
        if (seconds > 0)
            formatStrings.push(`${seconds} ${seconds === 1 ? labels.second.singular : labels.second.plural}`);
        if (milliseconds > 0)
            formatStrings.push(
                `${milliseconds} ${milliseconds === 1 ? labels.millisecond.singular : labels.millisecond.plural}`,
            );

        return formatStrings.join(' / ');
    };

    const formatToHighestMetric = (millis: number): { value: number; metric: string; metricShort: string } => {
        if (R.isNil(millis) || !R.is(Number, millis)) throw Error(`Cannot format duration '${millis}'`);
        if (millis < SECOND)
            return { value: millis, metric: millis === 1 ? 'millisecond' : 'milliseconds', metricShort: 'ms' };
        if (millis < MINUTE)
            return { value: millis / SECOND, metric: millis / SECOND === 1 ? 'second' : 'seconds', metricShort: 's' };
        if (millis < HOUR)
            return { value: millis / MINUTE, metric: millis / MINUTE === 1 ? 'minute' : 'minutes', metricShort: 'm' };
        if (millis < DAY)
            return { value: millis / HOUR, metric: millis / HOUR === 1 ? 'hour' : 'hours', metricShort: 'h' };

        return { value: millis / DAY, metric: millis / DAY === 1 ? 'day' : 'days', metricShort: 'd' };
    };

    const formatRangeDuration = (start: Date, end: Date) => {
        return formatMilliseconds(Math.abs(dayjsObj(start).diff(dayjsObj(end))));
    };

    return { formatMilliseconds, formatRangeDuration, formatToHighestMetric };
}
