import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import numeral from 'numeral';

dayjs.extend(relativeTime);
dayjs.extend(utc);

export function useFilters() {
    const formatBytes = (value: number) => numeral(value).format('0.0 b');
    const formatLargeNumber = (value: number) => numeral(value).format('0.0a').replace('.0', '');
    const splitCamelCase = (value: string) => value.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
    const splitLowerCase = (value: string) => {
        return splitCamelCase(value).toLowerCase();
    };
    const fromNow = (value: Date | string, forceUtc: boolean = false) =>
        forceUtc ? dayjs.utc(value).fromNow() : dayjs(value).fromNow();

    const formatNumber = (value: number) => {
        if (!Number.isInteger(value)) return value.toFixed(2);
        return value;
    };
    const countDecimals = (value: number) => {
        if (Math.floor(value) === Number(value)) return 0;
        return value.toString().split('.')[1].length || 0;
    };
    const formatDate = (value: string) => {
        return Intl.DateTimeFormat('en-US', { day: 'numeric', month: 'long', year: 'numeric' }).format(
            Date.parse(value),
        );
    };
    const formatDateTime = (value: string, forceUtc: boolean = true) => {
        return Intl.DateTimeFormat('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: 'numeric',
            timeZoneName: 'short',
            timeZone: forceUtc ? 'utc' : undefined,
            hour12: false,
        }).format(Date.parse(value));
    };
    const formatDecimals = (value: number, decimals: number) => {
        return parseFloat(Number(value).toFixed(decimals));
    };

    const formatDateStringAsIs = (value: string) => {
        const datetimeRegex = /^(\d{4})-(\d{2})-(\d{2})T([0-2][0-9]):([0-6][0-9]):([0-6][0-9])(.\d{6})?([+-]\d{2}:\d{2})$/;
        const matches = value.match(datetimeRegex);
        if (matches) {
            return `${matches[3]}/${matches[2]}/${matches[1]} ${matches[4]}:${matches[5]}:${matches[6]}${
                matches[7] ? `${matches[7].slice(0, 4)}` : '.000'
            } (${matches[8]})`;
        }
        return value;
    };

    const formatDateAs = (
        value: Date,
        forceUtc: boolean = false,
        template: string = 'DD/MM/YY HH:mm:ss',
        suffix?: string,
    ) => {
        const dayjsObj = forceUtc ? dayjs.utc(value) : dayjs(value);
        return dayjsObj.format(template) + (suffix ? ` ${suffix}` : '');
    };
    const capitalise = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    const formatCount = (count: number) => {
        if (count > 1000000000) return `${(count / 1000000000).toFixed(2)}b`;
        if (count > 1000000) return `${(count / 1000000).toFixed(2)}m`;
        if (count > 1000) return `${(count / 1000).toFixed(2)}k`;
        return count;
    };

    const formatSeconds = (seconds: number) => {
        if (seconds > 3600) return `${(seconds / 3600).toFixed(2)} hours`;
        if (seconds > 60) return `${(seconds / 60).toFixed(2)} minutes`;
        return `${seconds.toFixed(2)} seconds`;
    };

    const sortByKeyNames = (object: any) => {
        return Object.keys(object)
            .sort()
            .reduce((obj, key) => {
                // eslint-disable-next-line no-param-reassign
                obj[key] = object[key];
                return obj;
            }, {});
    };

    return {
        countDecimals,
        formatBytes,
        formatLargeNumber,
        formatDate,
        formatDateTime,
        formatNumber,
        fromNow,
        splitCamelCase,
        splitLowerCase,
        formatDecimals,
        formatDateAs,
        formatDateStringAsIs,
        capitalise,
        formatCount,
        formatSeconds,
        sortByKeyNames,
    };
}
